import { React, useEffect, useState, useRef } from "react";
import Header from "./SheroeHeader";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./css/Home.css";
import "slick-carousel/slick/slick-theme.css";
import YouTubeEmbed from "./YouTube";
import VideoFrame from "./Video";
import pics from "../data/robotics";
// import 'bootstrap/dist/css/bootstrap.min.css';
// Import the Bootstrap JS bundle globally
// import 'bootstrap/dist/js/bootstrap.bundle.min';

function NavItem({ id, text, active }) {
  return (
    <li className="nav-item" role="presentation">
      <button
        className={`nav-link${active ? " active" : ""}`}
        id={id}
        data-bs-toggle="pill"
        data-bs-target={`#${id}`}
        type="button"
        role="tab"
        aria-controls={id}
        aria-selected={active}
      >
        {text}
      </button>
    </li>
  );
}

function TabContent({ id, active, children }) {
  return (
    <div
      className={`tab-pane fade${active ? " show active" : ""}`}
      id={id}
      role="tabpanel"
      aria-labelledby={`${id}-tab`}
    >
      {children}
    </div>
  );
}

function TeamMemberModal({ id, pic }) {
  return (
    <div
      className="modal fade container-fluid light-modal-overlay"
      id={`modal-${id}`} // Unique ID for each modal
      tabIndex="-1"
      aria-labelledby={`modal-${id}-label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content border-0">
          <div className="modal-header flex-column">
            
          </div>
          <div className="modal-body">
            <img
              src={pic}
              alt={`Picture`}
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default function GrantDetail() {
  const [countdown, setCountdown] = useState(0);
  const joyNewsRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable auto-scrolling
    autoplaySpeed: 4000, // Set auto-scroll speed in milliseconds
  };

  const apiUrl = "https://fresh-link.onrender.com";

 

  function fetchData() {
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Data received:", data);
        // Handle the data as needed
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors
      });
  }

  // Call fetchData every 10 seconds
  setInterval(fetchData, 10000);

  useEffect(() => {
    // Function to scroll to a section based on hash
    const scrollToHash = () => {
      const hash = window.location.hash;
      const sectionRef = hash === "#joynews" ? joyNewsRef.current : null;
      if (sectionRef) {
        sectionRef.scrollIntoView({ behavior: "smooth" });
      }
    };

    // Scroll to section if hash exists
    if (window.location.hash) {
      scrollToHash();
    }

    // Set the target date for the countdown (replace 'targetDate' with your desired date)
    const targetDate = new Date("2024-04-26T09:00:00").getTime();

    // Update the countdown every second
    const interval = setInterval(() => {
      // Get the current date and time
      const now = new Date().getTime();

      // Calculate the remaining time in milliseconds
      const distance = targetDate - now;

      // Calculate days, hours, minutes, and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Update the countdown state
      setCountdown(`${days}d\t: \t${hours}h\t: \t${minutes}m\t: \t${seconds}s`);

      // If the countdown is over, clear the interval
      if (distance < 0) {
        clearInterval(interval);
        setCountdown("");
      }
    }, 1000);

    // Show the modal on page load
    const modalId = `modal-0`; // Assuming you want to show the first modal
    const modalElement = new window.bootstrap.Modal(document.getElementById(modalId));
    modalElement.show();

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      {/* <Header /> */}

      <section className="slick-slideshow-sheroes">
        <Slider {...settings} className="">
          <div className="slick-custom">
            <img src="images/prog/she.png" className="img-fluid" alt="" />

            <div className="slick-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-10">
                    <h2 className="slick-title">SHEROES in STEM</h2>
                    <h6 className="slick-title mt-lg-3 mb-lg-5">
                      Empowering Girls through Artificial Intelligence and
                      Robotics
                    </h6>
                    <Link to="/contact" className="btn custom-btn">
                      Get in touch
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slick-custom">
            <img
              src="images/slideshow/2A1A1624.jpg"
              className="img-fluid"
              alt=""
            />

            <div className="slick-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-10">
                    <h3 className="slick-title">MOLEX Foundation Africa</h3>

                    <p className="lead text-white mt-lg-3 mb-lg-5">
                      We are committed to enriching lives through the provision
                      of life coaching, career guidance, mentoring, and advocacy
                      services.
                    </p>

                    <Link to="/about" className="btn custom-btn">
                      Learn more about us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </section> 

     

      {pics.map((pic, index) => (
        <TeamMemberModal key={index} id={index} pic={pic} />
      ))}

      <Footer />
    </div>
  );
}

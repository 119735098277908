// Mission.jsx
import React, { useRef } from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import SideNav from "./SideNav";

function BoardDetail() {
  const missionRef = useRef(null);
  const visionRef = useRef(null);
  const coreValuesRef = useRef(null);
  const historyRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <Header />
      <section className="testimonial section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto text-center-no">
              {/* <div className="slick-testimonial" ref={missionRef}>
                <div className="slick-testimonialp-caption">
                  <p className="mission-text mb-5 pt-5 pt-lg-0">
                    <h5 className="text-center-no mt-5">
                      <span className="mt-5">Our Mission</span>
                    </h5>
                    Molex Found Africa is committed to enhancing quality of life
                    by offering life coaching, career guidance, mentoring, and
                    advocacy services that inspire individuals to pursue
                    greatness.
                  </p>
                </div>
              </div> */}

              <div className="slick-testimonial" ref={visionRef}>
                <div className="slick-testimonialp-caption">
                  <p className="mission-text mb-5">
                    <h5 className="text-center-no mt-5">
                      <span>Our</span> Vision
                    </h5>
                    <div className="vision-text">
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Create equal opportunities in our society for all to
                        pursue their dreams.
                      </p>
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Coach girls and women to live beyond the cultural and
                        gender limitations.
                      </p>
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Educate the public to develop resilience for Climate
                        Change catastrophe and participate in the mitigation and
                        adaptation measures.
                      </p>
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Combat against Sexual and Gender Based Violence.
                      </p>
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Spike students' interest in Science Technology
                        Engineering and Mathematics (STEM) especially the
                        girl-child.
                      </p>
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Raise leaders with great potential and ensure values
                        that will curb the required transformation of leadership
                        culture in the society.
                      </p>
                      {/* Add other vision points */}
                    </div>
                  </p>
                </div>
              </div>

           

            </div>

            {/* <SideNav
              scrollToMission={() => scrollToSection(missionRef)}
              scrollToVision={() => scrollToSection(visionRef)}
              scrollToCoreValues={() => scrollToSection(coreValuesRef)}
              scrollToHistory={() => scrollToSection(historyRef)}
            /> */}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default BoardDetail;

import React from "react";
import SideNav from "./SideNav";
import Header from "./Header";
import Footer from "./Footer";

function TeamMemberModal({ id, name, role, socialLinks, bio }) {
  return (
    <div
      className="modal fade"
      id={id}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content border-0">
          <div className="modal-header flex-column">
            <h3 className="modal-title" id="exampleModalLabel">
              {name}
            </h3>
            <h6 className="text-muted">{role}</h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row mb-4">
              <div className="col-lg-12 col-12 vision-text">
                <p
                  style={{ textAlign: "justify" }}
                  dangerouslySetInnerHTML={{ __html: bio }}
                ></p>
              </div>
            </div>
            {/* <ul className="social-icon">
              <li className="me-3">
                <strong>Where to find?</strong>
              </li>
              {socialLinks.map((link, index) => (
                <li key={index}>
                  <a
                    href={link.url}
                    className={`social-icon-link ${link.icon}`}
                  ></a>
                </li>
              ))}
            </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
}

function Executives() {
  const teamMembers = [
    {
      id: "patrick-essien",
      img: "3M3A1162.jpg",
      name: "Dr. Patrick Essien",
      role: "CEO",
      socialLinks: [
        { url: "#", icon: "bi-facebook" },
        { url: "#", icon: "bi-instagram" },
      ],
      bio: `<p>
      Dr.Patrick Essien is the founder and chief executive officer of The MOLEX Foundation Africa . He hails from a village called Nyanfeku Ekroful and has the zeal to help children and youths from poor background to become better people in our society through coaching, mentorship and career guidance. He is a gender advocate and confidence to promote gender equality in Science, Technology, Engineering and Mathematics (STEM). Due to his passion in creating equal opportunity for all, he dedicated a lot of his time for community service. He is currently a Lecturer at University of Cape Coast in Ghana and an AIMS Canada Postdoctoral research fellow. He is also a visiting lecturer at Africa Institute for Mathematical Sciences (AIMS) Senegal and formal consultant at AIMS Ghana who developed the climate and atmospheric master's program.
      </p>
      <p>
      Dr. Essien is a Space and Climate change enthusiast. Dr. Essien holds a Ph.D. in Space Geophysics from National Institute for Space Research (INPE) in Brazil, where he doubled as the assistance Scientist for the space weather program. His research interests focus is on the effects of space weather and atmospheric/ionospheric conditions on Global Navigation Satellite Systems (GNSS) and their applications. He is also working in the field of climate change. He received numerous scientific awards during his academic years including Max Hammond Student Award for the best abstract and project to present his Ph.D. work orally during America Geophysics Union (AGU) conference in 2019 in San Francisco.
      </p>
        He emerged as the best oral presentation during 2018 international COSPAR Capacity building workshop on space weather in Brazil, and the best scientist of the month (May, 2020) by Africa Geophysical Society (AGS). He has participated in numerous international conferences and has several publications in various international journals to his credit. Aside his academic prowess he has been holding several key leadership positions, among others, he is the former national point of contact to Ghana for the Space Generation Advisory Council, and a first foreigner to become the President of the Postgraduate Students of the National Institute for Space Research. Among others, he is a member of American Geophysical Union, European Geophysical Union, African Geophysical Society, Brazilian Symposium on Space Geophysics and Aeronomy and Ghana Institute of Physics.
      `,
    },
    
    {
      id: "kuta",
      img: "Untitled-8.png",
      name: "Emmanuel Kuta Essien",
      role: "Finance Officer",
      socialLinks: [
        { url: "#", icon: "bi-twitter" },
        { url: "#", icon: "bi-linkedin" },
        { url: "#", icon: "bi-envelope" },
      ],
      bio: `Mr Emmanuel kuta Essien is a graduate from the university of Education, Winneba in the Central Region of Ghana. He holds Degree in Bachelor of Business Administration in Accounting and currently he is a teller at Mon-Tran Limited Ghana. He is also a shareholder at Mfantseman Cassava Processing Factory at Anomabo in the central region of Ghana. He is also the CEO for EKE Perfect services and also the operations director and financial advisor to Ebenkate Training Institute. Mr Emmanuel Kuta Essien a social activist and willing to give back to the society through Molex Foundation Africa.`
    },
 
    {
      id: "don",
      img: "steve.png",
      name: "Stephen Nyankson",
      role: "Software Developer",
      socialLinks: [
        { url: "#", icon: "bi-youtube" },
        { url: "#", icon: "bi-whatsapp" },
        { url: "#", icon: "bi-instagram" },
      ],
      bio: `Stephen Nyankson is a dedicated IT professional with a Bachelor's Degree in Computer Science from the University of Cape Coast. 
      He currently works at the University of Cape Coast as IT support Specialist and doubles as Backend developer at Copianto AI.
      Stephen joined the Foundation in November 2023.<p/>
      Stephen is not only committed to advancing his career in Software Engineering but also to giving back to society through roles at 
     MOLEX Foundation Africa. With a passion for technology and a drive to make the world more connected, he is eager 
      to contribute his expertise to drive organizational objectives while continually improving himself.`,
    },
    {
      id: "kelly",
      img: "Untitled-10.png",
      name: "Ato-Turkson Daniel",
      role: "Operations",
      socialLinks: [
        { url: "#", icon: "bi-facebook" },
        { url: "#", icon: "bi-instagram" },
      ],
      bio: `<p>Born and raised in Nyanfeku Ekroful, in the Abura-Asebu-kwamankese District of Central Ghana, Mr. Ato-Turkson Daniel's journey embodies a commitment to education and community service. Beginning his schooling in Nyanfeku Ekroful, he later pursued his secondary education at Academy of Christ the King and later moverd to Sammo Otoo to better the result where is studiedBusinessas a course, both located in Cape Coast, Central Region.</p>
      <p>In 2017, he embarked on a career path, starting as a Sales Executive Officer at AFB Finance (now LetSheGo Savings and Loans), before transitioning to TF Finance Service as a Loan Master. </p>
      
      <p>In 2019, he furthered his education at the Premier and largest University in Ghana, University of Ghana- Legon to pursue B.A Accounting and Sociology.</p>
      
     <p> Deeply passionate about service, Mr. Ato-Turkson has been actively involved in numerous community organizations, including the Future Leaders Advocacy Group (FLAG), Abura Asebu Kwamankese Tertiary Student and Associates (AAKTSA), Nature Matters Foundation, EmmCom Foundation, ENACTUS UG. He has led various initiatives focusing on youth empowerment, environmental sustainability, and societal responsibility. His guiding principle, "No one owns us a responsibility but we own the society a responsibility," underscores his dedication to effecting positive change.</p>
      
      Currently, Mr. Ato-Turkson serves as a Service Personnel at the University of Cape Coast Directorate of Academic Affairs, working within the Admissions Section.`,
    },    
    {
      id: "tsiwah",
      img: "Untitled-4.png",
      name: "Prince Nana Tsiwah",
      role: "Social Media Manager",
      socialLinks: [
        { url: "#", icon: "bi-twitter" },
        { url: "#", icon: "bi-linkedin" },
        { url: "#", icon: "bi-envelope" },
      ],
      bio: `
      Born and bred in the quaint community of Ayeldo, Ghana, Prince Nana Tsiwah is a 26-year-old graduate of the University of Cape Coast. With a rich chocolate complexion, his passion lies in sharing academic knowledge and providing skilled training to the youth. Growing up in the central region of Ghana has instilled in him a deep-rooted desire to give back to his community and uplift the next generation. As he continues on his journey, he is committed to making a positive impact and contributing to the development of society`
    },

    {
      id: "ebo",
      img: "Untitled-2.png",
      name: "Dominic Ebo Arhin",
      role: "Communications",
      socialLinks: [
        { url: "#", icon: "bi-twitter" },
        { url: "#", icon: "bi-linkedin" },
        { url: "#", icon: "bi-envelope" },
      ],
      bio: `N/A`
    },
    {
      id: "rich",
      img: "Untitled-9.png",
      name: "Augustina Entsua-Mensah",
      role: "Secretary",
      socialLinks: [
        { url: "#", icon: "bi-twitter" },
        { url: "#", icon: "bi-linkedin" },
        { url: "#", icon: "bi-envelope" },
      ],
      bio: `N/A`
    },
    {
      id: "rich",
      img: "MIN. RICH.jpg",
      name: "Richmond Stephens",
      role: "Production",
      socialLinks: [
        { url: "#", icon: "bi-twitter" },
        { url: "#", icon: "bi-linkedin" },
        { url: "#", icon: "bi-envelope" },
      ],
      bio: `N/A`
    },
  ];

  return (
    <main>
      <Header />

      <section className="testimonial section-padding">
        <div className="container">
          <div className="row">
            <div className="row col-lg-12">
              <div className="col-12 text-center">
                <h2 className="mb-lg-5">
                  <span>Executives</span>
                </h2>
              </div>
              {teamMembers.map((member) => (
                <div
                  key={member.id}
                  className="col-lg-4 mb-4 col-12 align-items-center"
                >
                  <div className="team-thumb ">
                    <img
                      src={`images/team/${member.img}`}
                      className="img-fluid custom-circle-image team-image me-4"
                      alt=""
                    />
                    <div className="team-info text-center">
                      <div className="mb-0 capitalize">{member.name}</div>
                      <strong className="text-muted">{member.role}</strong>
                      <br />
                      <button
                        type="button"
                        className="btn btn-outline-dange custom-modal-btn"
                        data-bs-toggle="modal"
                        data-bs-target={`#${member.id}`}
                      >
                        Read more
                        <em className="mdi mdi-arrow-right-thin"></em>

                        {/* <i className="bi-plus-circle-fill text-light"></i> */}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* <SideNav /> */}
          </div>
        </div>
      </section>

      {/* Team Member Modals */}
      {teamMembers.map((member) => (
        <TeamMemberModal
          key={member.id}
          id={member.id}
          name={member.name}
          role={member.role}
          socialLinks={member.socialLinks}
          bio={member.bio}
        />
      ))}

      <Footer />
    </main>
  );
}

export default Executives;

import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./css/Home.css";

const program = [
  {
    name: "THE PINACLE JOURNEY",
    purpose: "aim",
    executiveSummary: `The show, "THE PINNACLE JOURNEY," endeavors to manifest as a 
        meticulously curated recorded video, meant to be shared on our YouTube channel. 
        This effort aims to pay tribute to the diverse careers, personal goals, and complicated 
        tapestry of life experiences that have collectively fashioned us into the people we are 
        today. At the heart of this endeavor is a deep-seated belief at MOLEX Foundation Africa,
         a belief that resonates with the tremendous significance of both personal narratives and the compelling influence of shared experiences.`,
    pictures: [
      "3M3A0215.jpg",
      "3M3A0118.jpg",
      "3M3A0131.jpg",
      "3M3A0119.jpg",
      "3M3A0119.jpg",
      "3M3A0119.jpg",
      "3M3A0119.jpg",
    ],
  },
];

export default function Grants() {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <Header />

      <header className="site-header section-padding-img site-header-image">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12 header-info">
              <h1>
                <span className="d-block text-primary">Grants & Awards</span>
              </h1>
            </div>
          </div>
        </div>
      </header>

      <section className="contact container mt-5 section-paddijng">
        <div className="container">
          {program.map((item, index) => (
            <div key={index} className="row border-bottkom mb-4">
              {/* <div className="col-lg-3 border-end d-flex justify-content-center align-items-center col-12">
                <h5>Executive Summary</h5>
              </div> */}
              <div className="col-lg-12 row  border col-12  ms-auto d-flex p-4" style={{justifyContent:'space-between', alignItems: 'center'}}>
                <div className="column col-lg-5 col-12">
                  <div className="col-12 vision-text contact-infoo">
                    {/* <p>{item.executiveSummary}</p> */}
                    <div className="grant-title">Girls-in-STEM initiative</div>
                    <div className="sub-title">STEM Convergence</div>
                    <div>Henry Luce Foundation - New York, United States</div>
                    <div className="text-danger">Nov 2023</div>
                  </div>
                </div>
                <Link to='#' target="_blank" className="col-lg-3 col-12 text-danger learn-more">Learn more <em className="mdi mdi-arrow-right-thin"></em></Link>
              </div>
            </div>
          ))}
        </div>


        <div className="container">
          {program.map((item, index) => (
            <div key={index} className="row border-bottkom mb-4">
              {/* <div className="col-lg-3 border-end d-flex justify-content-center align-items-center col-12">
                <h5>Executive Summary</h5>
              </div> */}
              <div className="col-lg-12 row  border col-12  ms-auto d-flex p-4" style={{justifyContent:'space-between', alignItems: 'center'}}>
                <div className="column col-lg-5 col-12">
                  <div className="col-12 vision-text contact-infoo">
                    {/* <p>{item.executiveSummary}</p> */}
                    <div className="grant-title">SHEROES in STEM initiative</div>
                    <div className="sub-title">Fisical Sponsor</div>
                    <div>AIMS Ghana - Accra, Ghana</div>
                    <div className="text-danger">Nov 2023</div>
                  </div>
                </div>
                <Link to='#' target="_blank" className="col-lg-3 col-12 text-danger learn-more">Learn more <em className="mdi mdi-arrow-right-thin"></em></Link>
              </div>
            </div>
          ))}
        </div>
      </section>

      <Footer />
    </div>
  );
}

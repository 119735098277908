import React from 'react';

const YouTubeEmbed = ({ videoId, width, height, id }) => (
  <div id={id} className="video-wrapper">
    <iframe
      width={width}
      height={height}
      src={`https://www.youtube.com/embed/${videoId}`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen // This allows the video to go fullscreen
    ></iframe>
  </div>
);

export default YouTubeEmbed;
import React, { useState, useEffect } from 'react';
import Header from './SheroeHeader';
import Footer from './Footer';
import './css/Home.css';

export default function ApplySheroes() {
  const [formData, setFormData] = useState({
    fullName: '',
    parent_fullName: '',
    dob: '',
    gender: '',
    phone: '',
    parent_phone: '',
    classForm: '',
    school: '',
    courseOfStudy: '',
    desiredCourse: '',
    specialNeeds: '',
    referral: '',
    remember: false
  });

  const [loading, setLoading] = useState(false); // Loading state for preloader
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Function to fetch data
    const fetchData = async () => {
      try {
        const response = await fetch('https://shelele-pin.onrender.com');
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        const result = await response.json();
        setData(result); // Save data to state
        setLoading(false); // Set loading to false
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData(); // Call the fetch function
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show preloader when form submission starts
    try {
      const response = await fetch('https://shelele-pin.onrender.com/submit-application', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      const result = await response.json();
      if (response.ok) {
        alert(result.message);
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error submitting application');
    } finally {
      setLoading(false); // Hide preloader after submission completes
    }
  };

  return (
    <div className="container-fluid">
      {loading && ( // Display preloader when loading is true
        <section className="preloader">
          <div className="spinner">
            <span className="sk-inner-circle"></span>
          </div>
        </section>
      )}
      <Header />
      <div className="container-fluid row mb-5 section-paddikng-img" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10vh" }}>
        <div className="container-fluid col-lg-5 site-header rounded mb-5 p-3">
          <form onSubmit={handleSubmit} className="was-validated">
            <div className="mb-3">
              <label htmlFor="fullName" className="form-label">Full name:</label>
              <input
                type="text"
                className="form-control"
                id="fullName"
                name="fullName"
                placeholder="Enter your full name"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
              <div className="valid-feedback">Valid.</div>
              <div className="invalid-feedback">Please fill out this field.</div>
            </div>

            <div className="mb-3">
              <label htmlFor="dob" className="form-label">Date of Birth:</label>
              <input
                type="date"
                className="form-control"
                id="dob"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                required
              />
              <div className="valid-feedback">Valid.</div>
              <div className="invalid-feedback">Please fill out this field.</div>
            </div>

            <div className="mb-3">
              <label htmlFor="gender" className="form-label">Gender:</label>
              <select className="form-select" id="gender" name="gender" value={formData.gender} onChange={handleChange} required>
                <option value="">Select gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
              <div className="valid-feedback">Valid.</div>
              <div className="invalid-feedback">Please select your gender.</div>
            </div>

            <div className="mb-3">
              <label htmlFor="phone" className="form-label">Phone Number:</label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                name="phone"
                placeholder="Enter phone number"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              <div className="valid-feedback">Valid.</div>
              <div className="invalid-feedback">Please fill out this field.</div>
            </div>

            <div className="mb-3">
              <label htmlFor="classForm" className="form-label">Class/Form:</label>
              <input
                type="text"
                className="form-control"
                id="classForm"
                name="classForm"
                placeholder="Enter class/form"
                value={formData.classForm}
                onChange={handleChange}
                required
              />
              <div className="valid-feedback">Valid.</div>
              <div className="invalid-feedback">Please fill out this field.</div>
            </div>

            <div className="mb-3">
              <label htmlFor="school" className="form-label">School/Institution (full):</label>
              <input
                type="text"
                className="form-control"
                id="school"
                name="school"
                placeholder="Enter school/institution"
                value={formData.school}
                onChange={handleChange}
                required
              />
              <div className="valid-feedback">Valid.</div>
              <div className="invalid-feedback">Please fill out this field.</div>
            </div>

            <div className="mb-3">
              <label htmlFor="courseOfStudy" className="form-label">Course of Study:</label>
              {/* <input
                type="text"
                className="form-control"
                id="courseOfStudy"
                name="courseOfStudy"
                placeholder="Enter course of study"
                value={formData.courseOfStudy}
                onChange={handleChange}
                required
              /> */}
              <select className="form-select" id="courseOfStudy" name="courseOfStudy" value={formData.courseOfStudy} onChange={handleChange} required>
                <option value="">Select course</option>
                <option value="General Science">General Science</option>
                <option value="General Arts">General Arts</option>
                <option value="Business">Business</option>
                <option value="Home Economics">Home Economics</option>
                <option value="Visual Arts">Visual Arts</option>
                <option value="Technical and Vocational">Technical and Vocational</option>
                <option value="Others">Others</option>
              </select>
              <div className="valid-feedback">Valid.</div>
              <div className="invalid-feedback">Please fill out this field.</div>
            </div>

            <div className="mb-3">
              <label htmlFor="desiredCourse" className="form-label">Select the course you want to study:</label>
              <select className="form-select" id="desiredCourse" name="desiredCourse" value={formData.desiredCourse} onChange={handleChange} required>
                <option value="">Select course</option>
                <option value="Artificial Intelligence and Robotics">Artificial Intelligence and Robotics</option>
              </select>
              <div className="valid-feedback">Valid.</div>
              <div className="invalid-feedback">Please select a course.</div>
            </div>

            <div className="mb-3">
              <label htmlFor="specialNeeds" className="form-label">Do you have any special need or requirement?</label>
              <textarea
                className="form-control"
                id="specialNeeds"
                name="specialNeeds"
                placeholder="Enter special needs"
                value={formData.specialNeeds}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="referral" className="form-label">How did you hear about our program?</label>
              <select className="form-select" id="referral" name="referral" value={formData.referral} onChange={handleChange} required>
                <option value="">Select an option</option>
                <option value="friend">Friend</option>
                <option value="social_media">Social Media</option>
                <option value="website">Website</option>
                <option value="recommendation">Recommendation</option>
                <option value="other">Other</option>
              </select>
              <div className="valid-feedback">Valid.</div>
              <div className="invalid-feedback">Please select an option.</div>
            </div>

            <div className="mb-3">
              <label htmlFor="fullName" className="form-label">Parent/Guardian Full name:</label>
              <input
                type="text"
                className="form-control"
                id="parent_fullName"
                name="parent_fullName"
                placeholder="Enter parent's full name"
                value={formData.parent_fullName}
                onChange={handleChange}
                required
              />
              <div className="valid-feedback">Valid.</div>
              <div className="invalid-feedback">Please fill out this field.</div>
            </div>

            <div className="mb-3">
              <label htmlFor="parent_phone" className="form-label">Parent/Guardian Phone Number:</label>
              <input
                type="tel"
                className="form-control"
                id="parent_phone"
                name="parent_phone"
                placeholder="Enter parent's phone number"
                value={formData.parent_phone}
                onChange={handleChange}
                required
              />
              <div className="valid-feedback">Valid.</div>
              <div className="invalid-feedback">Please fill out this field.</div>
            </div>
            
            <button type="submit" className="btn col-12 btn-primary">Submit</button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

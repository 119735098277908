import React from 'react';
// import './App.css';

function Bannar() {
  return (
    <div className="App">
      <div className="CONTAINER container-fluid">
        <div className="left-section">
          <h2>A place to create your legacy</h2>
          <p>Your work doesn't just solve problems. It shapes entire industries.</p>
        </div>
        <div className="right-section">
          <div className="card">
            <h3>What do we do?</h3>
            <p>
              Here, innovation isn't just an idea - it's part of our DNA. We're
              pioneering operations innovation, unlocking extraordinary efficiency,
              scale, and growth for startups and enterprises alike.
            </p>
          </div>
          <div className="card">
            <h3>How do we do it?</h3>
            <p>
              We are strategic partners who leverage the most advanced technologies,
              like AI and automation, and merge them with deeply thoughtful human
              intelligence to deliver powerful results.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bannar;

import { React, useEffect, useState, useRef } from "react";
import Header from "./SheroeHeader";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./css/Home.css";
import "slick-carousel/slick/slick-theme.css";
import YouTubeEmbed from "./YouTube";
import VideoFrame from "./Video";
import pics from "../data/robotics";

function NavItem({ id, text, active }) {
  return (
    <li className="nav-item" role="presentation">
      <button
        className={`nav-link${active ? " active" : ""}`}
        id={id}
        data-bs-toggle="pill"
        data-bs-target={`#${id}`}
        type="button"
        role="tab"
        aria-controls={id}
        aria-selected={active}
      >
        {text}
      </button>
    </li>
  );
}

function TabContent({ id, active, children }) {
  return (
    <div
      className={`tab-pane fade${active ? " show active" : ""}`}
      id={id}
      role="tabpanel"
      aria-labelledby={`${id}-tab`}
    >
      {children}
    </div>
  );
}

function TeamMemberModal({ id, pic }) {
  return (
    <div
      className="modal fade container-fluid"
      id={`modal-${id}`} // Unique ID for each modal
      tabIndex="-1"
      aria-labelledby={`modal-${id}-label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content border-0">
          <div className="modal-header flex-column">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <img
              src={pic}
              alt={`Picture`}
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Sheroes() {
  const [countdown, setCountdown] = useState(0);
  const joyNewsRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable auto-scrolling
    autoplaySpeed: 4000, // Set auto-scroll speed in milliseconds
  };

  const apiUrl = "https://fresh-link.onrender.com";

  const speakers = [
    {
      id: "patrick-essien",
      img: "2A1A1250.jpg",
      name: "Prof. Nana Ama Browne Klutse",
      role: "Head of Physics Department - University of Ghana, Vice Chair PCC",
      socialLinks: [
        { url: "#", icon: "bi-facebook" },
        { url: "#", icon: "bi-instagram" },
      ],
      bio: `
      `,
    },

    {
      id: "kuta",
      img: "Sarah_DeMartazzi_010924_098.jpg",
      name: "Sarah DeMartazzi",
      role: "Program Manager, Women in STEM - The Henry  Luce Foundation, New York",
      socialLinks: [
        { url: "#", icon: "bi-twitter" },
        { url: "#", icon: "bi-linkedin" },
        { url: "#", icon: "bi-envelope" },
      ],
      bio: ``,
    },

    {
      id: "don",
      img: "2A1A1342.jpg",
      name: "Dr. Regina Esi Turkson",
      role: "Senior Lecturer, Department of Computer Science and Information Technology - University of Cape Coast",
      socialLinks: [
        { url: "#", icon: "bi-youtube" },
        { url: "#", icon: "bi-whatsapp" },
        { url: "#", icon: "bi-instagram" },
      ],
      bio: ``,
    },
    {
      id: "kelly",
      img: "2A1A1270.jpg",
      name: "Adelaide Asantewa Asante",
      role: "Head of West Africa Office for UK Ecology and Hydrology",
      socialLinks: [
        { url: "#", icon: "bi-facebook" },
        { url: "#", icon: "bi-instagram" },
      ],
      bio: ``,
    },
    {
      id: "tsiwah",
      img: "2A1A1512.jpg",
      name: "Dr. Irene Kafui Vorsah Amponsah",
      role: "Lecturer, Department of Statistics - University of Cape Coast",
      socialLinks: [
        { url: "#", icon: "bi-twitter" },
        { url: "#", icon: "bi-linkedin" },
        { url: "#", icon: "bi-envelope" },
      ],
      bio: ``,
    },
  ];

  function fetchData() {
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Data received:", data);
        // Handle the data as needed
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors
      });
  }

  // Call fetchData every 10 seconds
  setInterval(fetchData, 10000);

  useEffect(() => {
    // Function to scroll to a section based on hash
    const scrollToHash = () => {
      const hash = window.location.hash;
      const sectionRef = hash === "#joynews" ? joyNewsRef.current : null;
      if (sectionRef) {
        sectionRef.scrollIntoView({ behavior: "smooth" });
      }
    };

    // Scroll to section if hash exists
    if (window.location.hash) {
      scrollToHash();
    }

    // Set the target date for the countdown (replace 'targetDate' with your desired date)
    const targetDate = new Date("2024-04-26T09:00:00").getTime();

    // Update the countdown every second
    const interval = setInterval(() => {
      // Get the current date and time
      const now = new Date().getTime();

      // Calculate the remaining time in milliseconds
      const distance = targetDate - now;

      // Calculate days, hours, minutes, and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Update the countdown state
      setCountdown(`${days}d\t: \t${hours}h\t: \t${minutes}m\t: \t${seconds}s`);

      // If the countdown is over, clear the interval
      if (distance < 0) {
        clearInterval(interval);
        setCountdown("");
      }
    }, 1000);

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Header />

      <section className="slick-slideshow-sheroes">
        <Slider {...settings} className="">
          <div className="slick-custom">
            <img src="images/prog/she.png" className="img-fluid" alt="" />

            <div className="slick-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-10">
                    <h2 className="slick-title">SHEROES in STEM</h2>
                    <h6 className="slick-title mt-lg-3 mb-lg-5">
                      Empowering Girls through Artificial Intelligence and
                      Robotics
                    </h6>
                    <Link to="/contact" className="btn custom-btn">
                      Get in touch
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slick-custom">
            <img
              src="images/slideshow/2A1A1624.jpg"
              className="img-fluid"
              alt=""
            />

            <div className="slick-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-10">
                    <h3 className="slick-title">MOLEX Foundation Africa</h3>

                    <p className="lead text-white mt-lg-3 mb-lg-5">
                      We are committed to enriching lives through the provision
                      of life coaching, career guidance, mentoring, and advocacy
                      services.
                    </p>

                    <Link to="/about" className="btn custom-btn">
                      Learn more about us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </section>

      <section className="about section-paddking mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12 border-colori">
              <div className="tab-content mt-2" id="pills-tabContent">
                <TabContent id="pills-home" active>
                  <div className="row">
                    <div className="col-12">
                      <div className="d- flex-column justify-content-center h-100 ms-lg-4 mt-lg-0 mt-5">
                        <h4 className="mb-3 text-center">
                          <span> About SHEROES in STEM</span>{" "}
                        </h4>
                        {/* <p>Little Fashion templates comes with <Link to="sign-in.html">sign in</Link> / <Link to="sign-up.html">sign up</Link> pages, product listing / product detail, about, FAQs, and contact page.</p> */}
                        <p className="b-text">
                          Welcome to SHEROES in STEM, a transformative
                          initiative by MOLEX FOUNDATION AFRICA, aimed at
                          bridging the gender gap in Science, Technology,
                          Engineering, and Mathematics (STEM). Our mission is to
                          empower and inspire girls to pursue careers in these
                          critical fields, providing them with the skills,
                          confidence, and opportunities to thrive.
                        </p>

                        <div className="mt-0 mt-lg-auto"></div>
                      </div>
                    </div>
                  </div>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about section-paddking mt-5">
        <div className="container slick-testimonial">
          <div className="slick-testimonialp-caption">
            <p className="mission-text mb-5">
              <h4 className="mb-3 text-center">
                <span> Goals and Objectives</span>{" "}
              </h4>
              <div className="vision-text">
                <p>
                  <em className="mdi mdi-checkbox-intermediate m-1"></em>
                  Increase the representation of girls in STEM education and
                  careers.
                </p>
                <p>
                  <em className="mdi mdi-checkbox-intermediate m-1"></em>
                  Provide a robust foundation in STEM subjects through a variety
                  of engaging and interactive activities.
                </p>
                <p>
                  <em className="mdi mdi-checkbox-intermediate m-1"></em>
                  Address and reduce the gender disparity in STEM fields.
                </p>

                <p>
                  <em className="mdi mdi-checkbox-intermediate m-1"></em>
                  Foster diversity and gender equality within the ICT
                  sector.Combat against Sexual and Gender Based Violence.
                </p>
              </div>
            </p>
          </div>
        </div>
      </section>

      <header className="site-header container-fluid text-center row section-paddinog-img site-header-image">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "centeri",
          }}
          className="container-fluid column col-12 col-lg-6"
        >
          <div className="container-fluid row col-12">
            <div className="col-12 ">
              <h2>
                <div className="text-primary">Why SHEROES in STEM?</div>
                <span className="text-dark theme">
                  {/*Empowering Girls Through Artificial Intelligence and Robotics*/}
                </span>
              </h2>
            </div>
          </div>
          <div
            style={{ flexDirection: "column" }}
            className="container-fluid d-flex justify-content-end  col-12  mt-3"
          >
            <p className="b-text col-lg-12">
              In a world where technological advancements are driving the
              future, women remain significantly underrepresented in STEM,
              particularly in developing regions like Ghana and Africa at large.
              SHEROES in STEM seeks to address this imbalance by offering a
              comprehensive and immersive learning experience through our annual
              STEM camp, targeting junior and senior high school girls.
            </p>
          </div>
        </div>
        <img
          src="images/prog/pic2.jpg"
          className="header-image img-fluid col-12 col-lg-5"
          alt=""
        />
      </header>

      <section className="about section-paddking mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12 border-colori">
              <div className="tab-content mt-2" id="pills-tabContent">
                <TabContent id="pills-home" active>
                  <div className="row">
                    <div className="col-12">
                      <div className="d- flex-column justify-content-center h-100 ms-lg-4 mt-lg-0 mt-5">
                        <h4 className="mb-3 text-center">
                          <span> The scope of the project</span>{" "}
                        </h4>
                        {/* <p>Little Fashion templates comes with <Link to="sign-in.html">sign in</Link> / <Link to="sign-up.html">sign up</Link> pages, product listing / product detail, about, FAQs, and contact page.</p> */}
                        <p className="b-text">
                          The scope of the SHEROES IN STEM program is
                          comprehensive and far-reaching. This initiative is
                          designed to empower and inspire young girls in both
                          Senior and Junior High Schools, primarily in rural
                          areas in Ghana, to excel in STEM fields. The program
                          spans a duration of one month each year, from 2024 to
                          2028, and targets approximately 60 students annually.
                        </p>

                        <div className="mt-0 mt-lg-auto"></div>
                      </div>
                    </div>
                  </div>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about section-paddking mt-5">
        <div className="container slick-testimonial">
          <div className="slick-testimonialp-caption">
            <p className="mission-text mb-5">
              <h4 className="mb-3 text-center">
                <span> Activities</span>{" "}
              </h4>
              <div className="vision-text">
                <p className="mb-5">
                  <div style={{ fontWeight: "bold" }}>
                    <em className="mdi mdi-checkbox-intermediate m-1"></em>
                    Coding and Robotics Workshops
                  </div>
                  Participants engage in hands-on coding exercises and robotics
                  training, learning programming languages like Python, and
                  micro-computer programming using ARDUINO.
                </p>

                <p>
                  <div style={{ fontWeight: "bold" }}>
                    <em className="mdi mdi-checkbox-intermediate m-1"></em>
                    Mentorship and Role Models
                  </div>
                  <br />
                  Accomplished women in STEM fields mentor the participants,
                  offering guidance, inspiration, and a glimpse into the
                  possibilities within STEM careers.
                </p>

                <p>
                  <div style={{ fontWeight: "bold" }}>
                    <em className="mdi mdi-checkbox-intermediate m-1"></em>
                    Hackathon and Exhibition
                  </div>
                  <br />
                  The program culminates in a grand Hackathon competition and
                  Exhibition, where girls showcase their talents, creativity,
                  and problem-solving abilities, serving as a testament to their
                  growth during the camp.
                </p>

                <p>
                  <div style={{ fontWeight: "bold" }}>
                    <em className="mdi mdi-checkbox-intermediate m-1"></em>
                    Field Trips
                  </div>
                  <br />
                  Participants visit university research labs and tech company
                  innovation hubs, gaining exposure to real-world STEM
                  environments and professional practices.
                </p>
                <p>
                  <em className="mdi mdi-checkbox-intermediate m-1"></em>
                  Provide a robust foundation in STEM subjects through a variety
                  of engaging and interactive activities.
                </p>
                <p>
                  <em className="mdi mdi-checkbox-intermediate m-1"></em>
                  Address and reduce the gender disparity in STEM fields.
                </p>

                <p>
                  <em className="mdi mdi-checkbox-intermediate m-1"></em>
                  Foster diversity and gender equality within the ICT
                  sector.Combat against Sexual and Gender Based Violence.
                </p>
              </div>
            </p>
          </div>
        </div>
      </section>



      <header className="site-header container-fluid text-center row section-paddinog-img site-header-image">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "centeri",
          }}
          className="container-fluid column col-12 col-lg-6"
        >
          <div className="container-fluid row col-12">
            <div className="col-12 ">
              <h2>
                <div className="text-primary">SHEROES in STEM</div>
                <span className="text-dark theme">
                  Empowering Girls Through Artificial Intelligence and Robotics
                </span>
              </h2>
            </div>
          </div>
          <div
            style={{ flexDirection: "column" }}
            className="container-fluid d-flex justify-content-end  col-12  mt-3"
          >
            <h3 className="text-">
              Launched on 26<sup>th</sup>April, 2024
            </h3>
            <h3 className="text-">
              @ SMS Auditorium - University of Cape Coast
            </h3>
            <div className="countdown">{countdown}</div>
          </div>
        </div>
        <img
          src="images/prog/pic3.jpeg"
          className="header-image img-fluid col-12 col-lg-5"
          alt=""
        />
      </header>

      <section
        className="about container-fluid d-flex section-paddking pt-4"
        style={{ justifyContent: "center" }}
      >
        <div className="container row">
          <div className="col-md-6">
            <YouTubeEmbed
              videoId="k8x84hOB4P4"
              width={400}
              height={300}
              id="video1"
            />
          </div>
          <div className="col-md-6">
            <YouTubeEmbed
              videoId="Fs2k_LJh2D4"
              width={400}
              height={300}
              id="video2"
            />
          </div>
        </div>
      </section>

      {/* <VideoFrame src="https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Fvideos%2Fjoynews.mp4?alt=media&token=c110ece5-d02e-45be-ba04-d337e0c19841" width={400} height={300} /> */}

      <section
        className="testimonial section-paddinig"
        style={{ backgroundColor: "rgb(255, 255, 255)" }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="row col-lg-12" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
              <div className="col-12 text-center">
                <h2 className="mt-5">
                  <span>speakers</span>
                </h2>
              </div>
              {speakers.map((member) => (
                <div
                  key={member.id}
                  className="col-lg-2 mb-4 col-12 align-items-center"
                >
                  <div className="team-thumb ">
                    <img
                      src={`images/speakers/${member.img}`}
                      className="img-fluid custom-circle-image team-image me-4"
                      alt=""
                    />
                    <div className="team-info text-center">
                      <h6 className="mb-0 capitalize">{member.name}</h6>
                      <strong className="text-danger">{member.role}</strong>
                      <br />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* <SideNav /> */}
          </div>
        </div>
      </section>

      <header className="site-header mb-5 container-fluid text-center row section-paddinog-img site-header-image">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "centeri",
          }}
          className="container-fluid column col-12 col-lg-6"
        >
           <div className="container-fluid row col-12">
            <div className="col-12 ">
              <h2>
                <div className="text-primary">Facilitators Training</div>
                <span className="text-dark theme">
                  Empowering Girls Through Artificial Intelligence and Robotics
                </span>
              </h2>
            </div>
          </div>
          <div className="container-fluid gallery-containero section-paddikng">
            <div className="row">
              {pics.map((pic, index) => (
                <div key={index} className="col-lg-2 col-md-4 col-6">
                  <img
                    src={pic}
                    alt={`Picture ${index}`}
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target={`#modal-${index}`} // Target the modal ID
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/auction-c5969.appspot.com/o/product%2FMOLEX%2Frobo_training%2F3M3A3680.JPG?alt=media&token=23a1edd9-dcab-4968-a347-54fc086fdc77"
          className="header-image img-fluid col-12 col-lg-6"
          alt=""
        />
      </header>

      {pics.map((pic, index) => (
        <TeamMemberModal key={index} id={index} pic={pic} />
      ))}

      <Footer />
    </div>
  );
}

import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./css/Home.css";

const program = [
  {
    name: "THE PINACLE JOURNEY",
    purpose: "aim",
    executiveSummary: `The show, "THE PINNACLE JOURNEY," endeavors to manifest as a 
        meticulously curated recorded video, meant to be shared on our YouTube channel. 
        This effort aims to pay tribute to the diverse careers, personal goals, and complicated 
        tapestry of life experiences that have collectively fashioned us into the people we are 
        today. At the heart of this endeavor is a deep-seated belief at MOLEX Foundation Africa,
         a belief that resonates with the tremendous significance of both personal narratives and the compelling influence of shared experiences.`,
    pictures: ["3M3A0215.jpg", "3M3A0118.jpg", "3M3A0131.jpg", "3M3A0119.jpg", "3M3A0119.jpg", "3M3A0119.jpg", "3M3A0119.jpg"],
  },
];

export default function Program() {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <Header />

      <header className="site-header section-padding-img site-header-image">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12 header-info">
              <h2>
                <span className="d-block text-primary">{program[0].name}</span>
              </h2>
            </div>
          </div>
        </div>
      </header>

      <section className="contact container mt-5 section-paddijng">
        <div className="container">
          {program.map((item, index) => (
            <div key={index} className="row border-bottkom mb-4">
              <div className="col-lg-3 border-end d-flex justify-content-center align-items-center col-12">
                <h5>Executive Summary</h5>
              </div>
              <div className="col-lg-9  border-bottom col-12 mt-0 ms-auto">
                <div className="row">
                  <div className="col-12 vision-text contact-inkfo">
                    <p>{item.executiveSummary}</p>
                    
                  </div>
                </div>
              </div>
              <div className="col-lg-3 border-end d-flex justify-content-center align-items-center col-12">
                <h5>Purpose</h5>
              </div>
              <div className="col-lg-9 border-bottom col-12 mt-0 ms-auto">
                <div className="row">
                  <div className="col-12  contact-info">
                    {/* <p>{item.executiveSummary}</p> */}
                    <div className="vision-text">
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        To empower individuals by sharing diverse life experiences and personal narratives.

                      </p>
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        To enhance skills through a comprehensive program that combines mentoring and hands-on learning.
                      </p>
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        To facilitate networking opportunities for participants.

                      </p>
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        To encourage self-discovery and personal growth.
                      </p>
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        To promote holistic development through immersive and disciplined methods.
                      </p>
               
                      {/* Add other vision points */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* <div className="container">
        <h4 className="text-center">Pictures</h4>
        <div className="row">
          {program.map((item, index) =>
            item.pictures.map((pic, idx) => (
              <div key={idx} className="col-lg-4 col-md-4 col-sm-6 mb-4">
                <img
                  src={"images/molex/" + pic}
                  alt={`Picture ${idx}`}
                  style={{ maxWidth: "100%", height: "auto" }} // Adjust the maximum width as needed
                />
              </div>
            ))
          )}
        </div>
      </div> */}

      <Footer />
    </div>
  );
}

// SideNav.jsx
import React from "react";
import { Link } from "react-router-dom";


function SideNav({ scrollToMission, scrollToVision, scrollToCoreValues, scrollToHistory }) {
  return (
    <div className="col-lg-3 text-wrap">
      <div className="nav-box text-center-no">
        <div className="nav-box-content">
          <Link to="/about" onClick={scrollToMission} className="white-text">
            Our Mission
          </Link>
        </div>
        <div className="nav-box-content">
          <Link to="/about" onClick={scrollToVision} className="white-text">
            Our Vision 
          </Link>
        </div>
        <div className="nav-box-content">
          <Link to="/about" onClick={scrollToCoreValues} className="white-text">
            Our Core values
          </Link>
        </div>
        <div className="nav-box-content">
          <Link to="/about" onClick={scrollToHistory} className="white-text">
            History
          </Link>
        </div>
        <div className="nav-box-content">
          <Link to="/about/executives" onClick={scrollToCoreValues} className="white-text">
            Executives
          </Link>
        </div>
        <div className="nav-box-content">
          <Link to="/about/board" className="white-text">
            Board of Directors
          </Link>
        </div>
        {/* Add other links as needed */}
      </div>
    </div>
  );
}

export default SideNav;

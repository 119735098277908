import { React, useEffect, useState, useRef } from "react";
// import Header from "./SheroeHeader";
import GuidingHeader from "./GuidingHeader";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./css/Home.css";
import "slick-carousel/slick/slick-theme.css";
import YouTubeEmbed from "./YouTube";
import VideoFrame from "./Video";
import ImageList from "./Gallery";
import pics from "../data/robotics";

function NavItem({ id, text, active }) {
  return (
    <li className="nav-item" role="presentation">
      <button
        className={`nav-link${active ? " active" : ""}`}
        id={id}
        data-bs-toggle="pill"
        data-bs-target={`#${id}`}
        type="button"
        role="tab"
        aria-controls={id}
        aria-selected={active}
      >
        {text}
      </button>
    </li>
  );
}

function TabContent({ id, active, children }) {
  return (
    <div
      className={`tab-pane fade${active ? " show active" : ""}`}
      id={id}
      role="tabpanel"
      aria-labelledby={`${id}-tab`}
    >
      {children}
    </div>
  );
}

function TeamMemberModal({ id, pic }) {
  return (
    <div
      className="modal fade container-fluid"
      id={`modal-${id}`} // Unique ID for each modal
      tabIndex="-1"
      aria-labelledby={`modal-${id}-label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content border-0">
          <div className="modal-header flex-column">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <img
              src={pic}
              alt={`Picture`}
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Robotics() {
  const [countdown, setCountdown] = useState(0);
  const joyNewsRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable auto-scrolling
    autoplaySpeed: 4000, // Set auto-scroll speed in milliseconds
  };

  const apiUrl = "https://fresh-link.onrender.com";

  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const fetchImageUrls = async () => {
      try {
        const response = await fetch(
          "https://fresh-link.onrender.com/api/images/"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch image URLs");
        }
        const data = await response.json();
        setImageUrls(data);
      } catch (error) {
        console.error("Error retrieving image URLs:", error);
      }
    };

    fetchImageUrls();
  }, []);

  return (
    <div>
      <GuidingHeader />

      <section className="slick-slideshow-sheroes">
        <Slider {...settings} className="">
          <div className="slick-custom pt-5 mt-5">
            <img src="images/molex/3M3A0212.jpg" className="img-fluid" alt="" />

            <div className="slick-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-10">
                    <h2 className="slick-title">Guiding Stars</h2>
                    <h6 className="slick-title mt-lg-3 mb-lg-5">
                      Mentorship and Coaching
                    </h6>
                    <Link to="/contact" className="btn custom-btn">
                      Get in touch
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </section>

      <section className="about section-paddking mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12 border-colori">
              <div className="tab-content mt-2" id="pills-tabContent">
                <TabContent id="pills-home" active>
                  <div className="row">
                    <div className="col-12">
                      <div className="d- flex-column justify-content-center h-100 ms-lg-4 mt-lg-0 mt-5">
                        <h4 className="mb-3 text-center">
                          <span> Background of Guiding Stars</span>{" "}
                        </h4>
                        <p className="b-text">
                          The Guiding Stars Initiative, launched by the MOLEX
                          Foundation Africa, aims to empower young individuals
                          and students to realize their full potential and
                          become drivers of positive change in their
                          communities. This project focuses on addressing the
                          challenges faced by Junior High Schools (JHS) and
                          Senior High Schools (SHS), especially in rural and
                          underserved areas. By providing mentorship, guidance,
                          and essential life skills, the initiative strives to
                          foster personal development and self-esteem among
                          economically disadvantaged students.
                        </p>
                        <h4 className="mb-3 text-center">
                          <span> Problem Statement</span>{" "}
                        </h4>
                        <p className="b-text">
                          Many children in our society face profound uncertainty
                          about their futures due to poverty and a lack of role
                          models. These children struggle to envision a brighter
                          future and often remain trapped in a cycle of poverty.
                          The absence of mentorship and support systems further
                          stifles their potential. It is crucial to address
                          these challenges and provide the necessary guidance,
                          support, and resources to help these children unlock
                          their potential and break free from poverty.
                        </p>

                        <header
                          className="site-header container-fluid text-center py-4 row section-paddinog-img site-header-image"
                          style={{ backgroundColor: "rgb(100, 10, 10)" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "centeri",
                            }}
                            className="container-fluid column col-12 col-lg-6"
                          >
                            <div className="container-fluid row col-12">
                              <div className="col-12 ">
                                <h2>
                                  <div className="text-light">Purpose</div>
                                  <span className="text-dark theme">
                                    {/*Empowering Girls Through Artificial Intelligence and Robotics*/}
                                  </span>
                                </h2>
                              </div>
                            </div>

                            <div className="container gallery-container section-paddikng">
                              <div className="row">
                                {pics.map((pic, index) => (
                                  <div
                                    key={index}
                                    className="col-lg-3 col-md-4 col-6"
                                  >
                                    <img
                                      src={pic}
                                      alt={`Picture ${index}`}
                                      style={{
                                        maxWidth: "100%",
                                        height: "auto",
                                      }}
                                      data-bs-toggle="modal"
                                      data-bs-target={`#modal-${index}`} // Target the modal ID
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                           
                          </div>
                          <img
                            src="images/molex/3M3A0215.jpg"
                            className="header-image img-fluid col-12 col-lg-5"
                            alt=""
                          />
                        </header>

                        <h4 className="my-5 text-center">
                          <span> Scope</span>{" "}
                        </h4>
                        <p className="b-text">
                          <h5>The initiative focuses on:</h5>
                          <ul>
                            <li>
                              Vulnerable and economically disadvantaged students
                              in JHS and SHS.
                            </li>
                            <li>
                              Providing mentorship and coaching to build
                              self-esteem and set achievable goals.
                            </li>
                            <li>
                              Equipping students with life skills to overcome
                              obstacles and unlock their potential.
                            </li>
                            <li>
                              Encouraging community engagement and civic
                              responsibility.
                            </li>
                            <li>
                              Ensuring long-term support for sustained growth
                              and development.
                            </li>
                          </ul>
                        </p>

                        <h5 className="text-center">Pedu M/A Basic School</h5>

                        <header
                          className="site-headerj container-fluid text-center row section-paddinog-img site-header-image"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <img
                            src="images/molex/3M3A0215.jpg"
                            className="header-image img-fluid col-12 col-lg-6"
                            alt=""
                          />
                          <img
                            src="images/molex/3M3A0215.jpg"
                            className="header-image img-fluid col-12 col-lg-6"
                            alt=""
                          />
                        </header>

                        <h5 className="text-center pt-5">
                          <span>Abrem Essiam Basic School</span>
                        </h5>

                        <header
                          className="site-headerj container-fluid text-center row section-paddinog-img site-header-image"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <img
                            src="images/molex/03.jpeg"
                            className="header-image img-fluid col-12 col-lg-6"
                            alt=""
                          />
                          <img
                            src="images/molex/05.jpeg"
                            className="header-image img-fluid col-12 col-lg-6"
                            alt=""
                          />
                        </header>

                        {/* <ImageList/> */}

                        <h4 className="mb-3 text-center">
                          <span> Activities</span>{" "}
                        </h4>
                        <p className="b-text">
                          The Guiding Stars Initiative encompasses several key
                          components:
                          <ul>
                            <li>
                              <strong>Mentorship and Coaching:</strong> Trained
                              mentors and university students visit schools to
                              provide personalized guidance, helping students
                              build self-esteem and set future goals.
                            </li>
                            <li>
                              <strong>Workshops and Seminars:</strong> Organized
                              sessions on personal development, communication,
                              leadership, and problem-solving skills.
                            </li>
                            <li>
                              <strong>Community Engagement:</strong> Encouraging
                              students to participate in volunteerism and civic
                              projects, fostering a sense of responsibility and
                              leadership.
                            </li>
                            <li>
                              <strong>Long-term Support:</strong> Providing
                              ongoing mentorship and resources to ensure
                              sustained growth beyond the project's duration.
                            </li>
                            <li>
                              <strong>Collaborations:</strong> Working with
                              schools, community organizations, and stakeholders
                              to maximize the initiative's impact.
                            </li>
                          </ul>
                        </p>

                        <header
                          className="site-header container-fluid text-center mb-5 py-4 row section-paddinog-img site-header-image"
                          style={{ backgroundColor: "rgb(10, 10, 10)" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "centeri",
                            }}
                            className="container-fluid column col-12 col-lg-6"
                          >
                            <div className="container-fluid row col-12">
                              <div className="col-12 ">
                                <h2>
                                  <div className="text-light">
                                    Goals and Objectives
                                  </div>
                                  <span className="text-dark theme">
                                    {/*Empowering Girls Through Artificial Intelligence and Robotics*/}
                                  </span>
                                </h2>
                              </div>
                            </div>
                            <div
                              style={{ flexDirection: "column" }}
                              className="container-fluid d-flex justify-content-end  col-12  mt-3"
                            >
                              <p className="b-text text-light col-lg-12">
                                <ul
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    textAlign: "left ",
                                  }}
                                >
                                  <li>
                                    Empower vulnerable students to realize their
                                    potential and become positive change agents.
                                  </li>
                                  <li>
                                    Foster personal development through
                                    essential life skills and resilience.
                                  </li>
                                  <li>
                                    Promote community engagement and civic
                                    responsibility.
                                  </li>
                                  <li>
                                    Break the cycle of poverty by supporting
                                    academic success and economic mobility.
                                  </li>
                                  <li>
                                    Ensure sustainability through support
                                    networks and partnerships.
                                  </li>
                                </ul>
                              </p>
                            </div>
                          </div>
                          <img
                            src="images/molex/3M3A0139.jpg"
                            className="header-image img-fluid col-12 col-lg-5"
                            alt=""
                          />
                        </header>

                        <div className="mt-0 mt-lg-auto"></div>
                      </div>
                    </div>
                  </div>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </section>

      {pics.map((pic, index) => (
                              <TeamMemberModal
                                key={index}
                                id={index}
                                pic={pic}
                              />
                            ))}

      <Footer />
    </div>
  );
}

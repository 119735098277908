import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

function TeamMemberModal({ id, pic }) {
  return (
    <div
      className="modal fade container-fluid"
      id={`modal-${id}`} // Unique ID for each modal
      tabIndex="-1"
      aria-labelledby={`modal-${id}-label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content border-0">
          <div className="modal-header flex-column">
         
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <img src={pic} alt={`Picture`} style={{ width: "100%", height: "auto" }} />
          </div>
        </div>
      </div>
    </div>
  );
}

const ImageList = () => {
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const fetchImageUrls = async () => {
      try {
        const response = await fetch("http://localhost:3000/api/images");
        if (!response.ok) {
          throw new Error("Failed to fetch image URLs");
        }
        const data = await response.json();
        setImageUrls(data);
      } catch (error) {
        console.error("Error retrieving image URLs:", error);
      }
    };

    fetchImageUrls();
  }, []);

  return (
    <div>
      <Header />

      <section className="slick-slideshow">
        <div className="slick-custom">
          <img src="images/molex/3M3A0113.jpg" className="img-fluid" alt="" />

          <div className="slick-bottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-10">
                  <h3 className="slick-title">MOLEX Foundation Africa</h3>

                  <p className="lead text-white mt-lg-3 mb-lg-5">
                    We are committed to enriching lives through the provision of
                    life coaching, career guidance, mentoring, and advocacy
                    services.
                  </p>

                  {/* <Link to="/about" className="btn custom-btn">
                    Learn more about us
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="slick-custom">
                <img src="images/slideshow/team-meeting-renewable-energy-project.jpeg" className="img-fluid" alt="" />

                <div className="slick-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-10">
                                <h1 className="slick-title">New Design</h1>

                                <p className="lead text-white mt-lg-3 mb-lg-5">Please share this Little Fashion template to your friends. Thank you for supporting us.</p>

                                <Link to="product.html" className="btn custom-btn">Explore products</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="slick-custom">
                <img src="images/slideshow/two-business-partners-working-together-office-computer.jpeg" className="img-fluid" alt="" />

                <div className="slick-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-10">
                                <h1 className="slick-title">Talk to us</h1>

                                <p className="lead text-white mt-lg-3 mb-lg-5">Tooplate is one of the best HTML CSS template websites for everyone.</p>

                                <Link to="contact.html" className="btn custom-btn">Work with us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      </section>
      {/* <header className="site-header section-padding-img site-header-image">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12 header-info">
              <h1>
                <span className="d-block text-primary">Our Gallery</span>
              </h1>
            </div>
          </div>
        </div>
      </header> */}
      <div className="container">
        <div className="row">
          {imageUrls.map((pic, index) => (
            <div key={index} className="col-lg-3 col-md-4 col-sm-6 mb-4">
              <img
                src={pic}
                alt={`Picture ${index}`}
                style={{ maxWidth: "100%", height: "auto" }}
                data-bs-toggle="modal"
                data-bs-target={`#modal-${index}`} // Target the modal ID
              />
            </div>
          ))}
        </div>
      </div>
      {imageUrls.map((pic, index) => (
        <TeamMemberModal key={index} id={index} pic={pic}/>
      ))}
    </div>
  );
};

export default ImageList;

import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

function TeamMemberModal({ id, pic }) {
  return (
    <div
      className="modal fade container-fluid"
      id={`modal-${id}`} // Unique ID for each modal
      tabIndex="-1"
      aria-labelledby={`modal-${id}-label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content border-0">
          <div className="modal-header flex-column">
         
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <img src={pic} alt={`Picture`} style={{ width: "100%", height: "auto" }} />
          </div>
        </div>
      </div>
    </div>
  );
}

const ImageList = () => {
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const fetchImageUrls = async () => {
      try {
        const response = await fetch("https://fresh-link.onrender.com/api/images/");
        if (!response.ok) {
          throw new Error("Failed to fetch image URLs");
        }
        const data = await response.json();
        setImageUrls(data);
      } catch (error) {
        console.error("Error retrieving image URLs:", error);
      }
    };

    fetchImageUrls();
  }, []);

  return (
    <div>
      <Header />

      <div className="container gallery-container section-padding mt-5">
        <div className="row">
          {imageUrls.map((pic, index) => (
            <div key={index} className="col-lg-3 col-md-4 col-6 mb-4">
              <img
                src={pic}
                alt={`Picture ${index}`}
                style={{ maxWidth: "100%", height: "auto" }}
                data-bs-toggle="modal"
                data-bs-target={`#modal-${index}`} // Target the modal ID
              />
            </div>
          ))}
        </div>
      </div>
      {imageUrls.map((pic, index) => (
        <TeamMemberModal key={index} id={index} pic={pic}/>
      ))}
      <Footer/>
    </div>
  );
};

export default ImageList;

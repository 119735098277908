import React from "react";
import SideNav from "./SideNav";
import Header from "./Header";
import Footer from "./Footer";
import teamMembers from "../data/board";

function TeamMemberModal({ id, name, role, socialLinks, bio }) {
  return (
    <div
      className="modal fade"
      id={id}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content border-0">
          <div className="modal-header flex-column">
            <h3 className="modal-title" id="exampleModalLabel">
              {name}
            </h3>
            <h6 className="text-muted">{role}</h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">

            <div className="row mb-4">
              <div className="col-lg-12 col-12 vision-text">
                <p style={{ textAlign: "justify"}} dangerouslySetInnerHTML={{ __html: bio }}></p>
              </div>
            </div>
            {/* <ul className="social-icon">
              <li className="me-3">
                <strong>Where to find?</strong>
              </li>
              {socialLinks.map((link, index) => (
                <li key={index}>
                  <a
                    href={link.url}
                    className={`social-icon-link ${link.icon}`}
                  ></a>
                </li>
              ))}
            </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
}

function Board() {

  return (
    <main>
      <Header />

      <section className="testimonial section-padding">
        <div className="container">
          <div className="row">
            <div className="row col-lg-12">
              <div className="col-12 text-center">
                <h2 className="mb-lg-5">
                  <span>Board of Directors</span>
                </h2>
              </div>
              {teamMembers.map((member) => (
                <div
                  key={member.id}
                  className="col-lg-4 mb-4 col-12 align-items-center"
                >
                  <div className="team-thumb ">
                    <img
                      src={`images/team/${member.img}`}
                      className="img-fluid custom-circle-image team-image me-4"
                      alt=""
                    />
                    <div className="team-info">
                      <div className="mb-0">{member.name}</div>
                      <strong className="text-muted">{member.role}</strong><br/>
                      <button
                        type="button"
                        className="btn btn-outline-dange custom-modal-btn"
                        data-bs-toggle="modal"
                        data-bs-target={`#${member.id}`}
                      >
                        Read more
                        {/* <i className="bi-plus-circle-fill text-light"></i> */}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* <SideNav /> */}
          </div>
        </div>
      </section>

      {/* Team Member Modals */}
      {teamMembers.map((member) => (
        <TeamMemberModal
          key={member.id}
          id={member.id}
          name={member.name}
          role={member.role}
          socialLinks={member.socialLinks}
          bio={member.bio}
        />
      ))}

      <Footer />
    </main>
  );
}

export default Board;

const teamMembers = [
  {
    id: "patrick-essien",
    img: "3M3A1162.jpg",
    name: "Dr.Patrick Essien",
    role: "CEO",
    socialLinks: [
      { url: "#", icon: "bi-facebook" },
      { url: "#", icon: "bi-instagram" },
    ],
    bio: `<p>
    Dr.Patrick Essien is the founder and chief executive officer of The MOLEX Foundation Africa . He hails from a village called Nyanfeku Ekroful and has the zeal to help children and youths from poor background to become better people in our society through coaching, mentorship and career guidance. He is a gender advocate and confidence to promote gender equality in Science, Technology, Engineering and Mathematics (STEM). Due to his passion in creating equal opportunity for all, he dedicated a lot of his time for community service. He is currently a Lecturer at University of Cape Coast in Ghana and an AIMS Canada Postdoctoral research fellow. He is also a visiting lecturer at Africa Institute for Mathematical Sciences (AIMS) Senegal and formal consultant at AIMS Ghana who developed the climate and atmospheric master's program.
    </p>
    <p>
    Dr. Essien is a Space and Climate change enthusiast. Dr. Essien holds a Ph.D. in Space Geophysics from National Institute for Space Research (INPE) in Brazil, where he doubled as the assistance Scientist for the space weather program. His research interests focus is on the effects of space weather and atmospheric/ionospheric conditions on Global Navigation Satellite Systems (GNSS) and their applications. He is also working in the field of climate change. He received numerous scientific awards during his academic years including Max Hammond Student Award for the best abstract and project to present his Ph.D. work orally during America Geophysics Union (AGU) conference in 2019 in San Francisco.
    </p>
      He emerged as the best oral presentation during 2018 international COSPAR Capacity building workshop on space weather in Brazil, and the best scientist of the month (May, 2020) by Africa Geophysical Society (AGS). He has participated in numerous international conferences and has several publications in various international journals to his credit. Aside his academic prowess he has been holding several key leadership positions, among others, he is the former national point of contact to Ghana for the Space Generation Advisory Council, and a first foreigner to become the President of the Postgraduate Students of the National Institute for Space Research. Among others, he is a member of American Geophysical Union, European Geophysical Union, African Geophysical Society, Brazilian Symposium on Space Geophysics and Aeronomy and Ghana Institute of Physics.
    `,
  },
  {
    id: "captain",
    img: "captain.jpg",
    name: "Capt. Tabitha Simons-Dadzie",
    role: "Chair",
    socialLinks: [
      { url: "#", icon: "bi-twitter" },
      { url: "#", icon: "bi-linkedin" },
      { url: "#", icon: "bi-envelope" },
    ],
    bio: `<p>
    Captain Tabitha Simons-Dadzie's life journey is a testament to perseverance and dedication to
healthcare. Born in Nyanfeku Ekroful, Ghana, she began her education locally before pursuing
nursing at Cape Coast Nursing and Midwifery Training School, driven by her passion for quality
healthcare.
    </p>
    <p>
    Tabitha's career flourished as she contributed significantly to healthcare institutions, including
Cape Coast Teaching Hospital, Cape Coast Metropolitan Hospital, Adisadel, and Ewim
Polyclinic, earning respect for her unwavering dedication and professionalism.
    </p>
    <p>
    She further specialized in Ears, Nose, and Throat (E.N.T) medicine at Komfo Anokye Teaching
Hospital, enhancing her ability to provide specialized care. Her journey then took her to the
Ghana Armed Forces, where she excelled as an E.N.T Specialist, achieving the rank of Captain
and serving at the 37 Military Hospital in Accra.
</p><p>
Beyond her medical career, Tabitha chairs the Molex Foundation Africa, advocating for the girl-
child in STEM fields and gender equality. My commitment to the well-being of others, especially
the girl-child has propelled me to give back to society. I am passionate about helping the girl-
child to bridge gender disparity and ensuring that the girl-child is mentored and coached to be
able to learn, decide, and thrive in STEM fields.
    </p>
    `,
  },
  {
    id: "nana-ama",
    img: "HeadshotNana.jpg",
    name: "Prof. Mrs. Nana Ama Browne Klutse",
    role: "Member",
    socialLinks: [
      { url: "#", icon: "bi-youtube" },
      { url: "#", icon: "bi-whatsapp" },
      { url: "#", icon: "bi-instagram" },
    ],
    bio: `<p>Prof. Nana Ama Browne Klutse is a distinguished scholar and climatologist with a proven track record of significant contributions to understanding climate change and its impacts. Her academic background and professional achievements exemplify her deep commitment to advancing climate science, fostering international collaboration, and promoting sustainable practices. Prof. Klutse has extensive experience in research, leadership, and service in the field of climate change.  She has contributed substantially to various climate-related initiatives. She is Associate Professor and Head of the Department of Physics, University of Ghana.</p>

    <p>Prof. Klutse is Vice Chair of the Working Group I of the Intergovernmental Panel on Climate Change (IPCC).  She has been a key contributor to several international working groups, panels, and scientific committees focused on climate change Science, adaptation and mitigation. She has served the IPCC in various capacities for over seven years. She served as a Lead Author in Working Group I of the Sixth Assessment Report and a Member of the Task Group on Data Support for the IPCC Sixth Assessment Report, among others. She also represented Ghana in IPCC plenary sessions.</p>
    
    <p>Prof Klutse serves also as a board member of the UNESCO Basic Sciences Programme. She is also a Researcher at the African Institute of Mathematical Sciences. Her expertise in synthesizing complex scientific information and translating it into actionable policy recommendations has profoundly impacted climate change adaptation and mitigation efforts at global, regional and national levels. Academic and governmental institutions have recognised Prof. Klutse's influence on policy formulation and implementation, further affirming her credibility and competence in the field.</p>`,
  },
  {
    id: "adelaide",
    img: "2A1A1159.jpg",
    name: "Adelaide Asantewaa Asante",
    role: "Member",
    socialLinks: [
      { url: "#", icon: "bi-youtube" },
      { url: "#", icon: "bi-whatsapp" },
      { url: "#", icon: "bi-instagram" },
    ],
    bio: `<p>
    Adelaide Asantewaa Asante is currently Head of West Africa Office for the UK Centre for Ecology & Hydrology. Based in Accra, Ghana, she has responsibility of playing a key role in the development of UKCEH's research and business interests in West Africa, as well as managing the day-to-day operations of the office. She works with colleagues across UKCEH to maximize the impacts of the organization's research across the sub-region and to facilitate the delivery of UKCEH scientific projects in West Africa.
</p><p>
Adelaide has had several years of experience in education and research management when she worked with the African Institute for Mathematical Sciences (AIMS) Ghana as a Chief Operating Officer. Prior to joining AIMS Ghana, she worked with the Government of Ghana through the Ministry of Environment, Science, Technology and Innovation for more than 10 years as a Science and Technology Officer and later became the Director of Science, Technology and Innovation where she provided policy advice as well as led in the development and implementation of STI programmes and projects, notably the establishment of the Ghana's first STI policy, Ghana Space Science and Technology Institute, and the Ghana Radio Astronomy Observatory. She has a deep passion for STI and STEM education and also have keen interest in girls and women in STEM.
</p><p>
Adelaide Asante is a product of University Practice Secondary School, KNUST and Karlsruhe Institute of Technology, Germany.
</p>
    `,
  },
  
  {
    id: "don",
    img: "2A1A1078.jpg",
    name: "Dr. Francis Nkrumah",
    role: "Member",
    socialLinks: [
      { url: "#", icon: "bi-youtube" },
      { url: "#", icon: "bi-whatsapp" },
      { url: "#", icon: "bi-instagram" },
    ],
    bio: `N/A
        `,
  },
  {
    id: "kelly",
    img: "micky.jpeg",
    name: "Mr. Michael Erasmus Edumadzie",
    role: "Member",
    socialLinks: [
      { url: "#", icon: "bi-facebook" },
      { url: "#", icon: "bi-instagram" },
    ],
    bio: `<p>
    A board member of MOLEX Foundation Africa. Sir Micky is a Teacher and Sports Development Officer by profession in GES with over 20 years of teaching and coaching experience. Having taught in several basic schools, six senior high schools, a municipal and a district education officer, a former University of Mines and Technology (UMaT) Head coach and a supporting lecturer at the University of Education, Winneba. 
    </p>
    <p>
    He has a strong ITC background and is an ICT Integration in Education advocater. He is a Robotics Instructor and Programmer (EV-3). He has also worked with various NGOs like Projects Abroad (UK in Ghana), Young and Lonely Foundation (Swedru, Ghana), Project Ten (Israel, Ghana) former MD of RAAMEESDA Foundation (Winneba, Ghana) and a cofounder of Parallel Integration (Winneba, Ghana). 
    </p>
    <p>
    He is a research fellow and a continuous learner who has an insatiable passion and desire to help the upcoming generation to develop, to attain self reliance and self sufficiency. He has a couple of publications with astute home economists and an editor in chief for the book The Fundamentals in Entrepreneurial Skills for Schools and colleges. 
    </p>
    He hails from Winneba, Effutu Municipality in the Central Region of Ghana. He is a husband and a father of three girls.
        `,
  },
  {
    id: "nat",
    img: "pic120.png",
    name: "Mr. Nathaniel Bimpong",
    role: "Member",
    socialLinks: [
      { url: "#", icon: "bi-twitter" },
      { url: "#", icon: "bi-linkedin" },
      { url: "#", icon: "bi-envelope" },
    ],
    bio: `<p>
    A Tutor as Mfantsipim School and a PhD candidate specializing in geography and climatology. 
His passion is to care for the environment and its scarce resources for the sole benefit of venerable children and woman, both now and in the future.
    </p>`,
  },
  {
    id: "ivy",
    img: "2A1A1327.jpg",
    name: "Miss Ivy Amartey",
    role: "Member",
    socialLinks: [
      { url: "#", icon: "bi-twitter" },
      { url: "#", icon: "bi-linkedin" },
      { url: "#", icon: "bi-envelope" },
    ],
    bio: `<p>
    AA board member of MOLEX Foundation Africa. Ms. Amartey is the acting Central Regional Director of Department of Children. From Nyanfeku Ekroful in the Abura-Asebu-Kwamankese District in the central region of Ghana

    She is a professional social worker who is passionate about the vulnerable/ marginalized/ excluded expecially children.
    </p>`,
  },
  {
    id: "mariam",
    name: "Ms. Mariam Abubekar",
    img: "39.jpg",
    role: "Member",
    socialLinks: [
      { url: "#", icon: "bi-twitter" },
      { url: "#", icon: "bi-linkedin" },
      { url: "#", icon: "bi-envelope" },
    ],
    bio: `<p class="about_description">A board member of  MOLEX Foundation Africa. Ms. Mariam Abubekar is a Principal Administrative Assistance and Directorate of Academic Affairs (Admission Office) at University of Cape Coast.
    Administrator with over fourteen years experience in administration. Self direction with good communication skills and self directed task completion talent.
    Willing to take additional responsibility to achieve organizational goal and also have passion for humanity</p>
        `,
  },
   {
    id: "regina",
    img: "2A1A1342.jpg",
    name: "Dr. Regina Turkson",
    role: "Member", 
    socialLinks: [
      { url: "#", icon: "bi-facebook" },
      { url: "#", icon: "bi-instagram" },
    ],
    bio: `N/A`,
  },
];

export default teamMembers;

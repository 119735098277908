import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";

const Volunteer = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    region: '',
    city: '',
    department: '',
    reason: '',
  });
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);


  useEffect(() => {
    // Function to fetch data
    const fetchData = async () => {
      try {
        const response = await fetch('https://shelele-pin.onrender.com');
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        const result = await response.json();
        setData(result); // Save data to state
        setLoading(false); // Set loading to false
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData(); // Call the fetch function
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Show loader when submission starts
    setIsSubmitted(false); // Reset submission state

    try {
      const response = await fetch('https://shelele-pin.onrender.com/submit-volunteer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response from server:', errorText);
        throw new Error(`Server error: ${response.status}`);
      }

      const result = await response.json();
      setIsSubmitted(true); // Mark as submitted on success
      alert(result.message);
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit the form. Please try again later.');
    } finally {
      setIsSubmitting(false); // Hide loader once submission is done
    }
  };

  return (
    <div>
      <Header />

      {isSubmitting && (
       <section className="preloader">
       <div className="spinner">
         <span className="sk-inner-circle"></span>
       </div>
     </section>
      )}

      {/* {isSubmitted && (
        <div className="alert alert-success" role="alert">
          Form submitted successfully!
        </div>
      )} */}

      {!isSubmitting &&(
        <div className="modalk">
          <Link className="text-light text-center container-fluid">
            Hwihjiu
          </Link>
          <div className="content">
            <form onSubmit={handleSubmit}>
              <div className="container">
                <h2 className="text-center">Join us</h2>
                <p>Please fill in this form to become a volunteer.</p>
                <hr />
                <label htmlFor="fullName">
                  <b>Full name</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter full name"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />

                <label htmlFor="email">
                  <b>Email</b>
                </label>
                <input
                  type="email"
                  placeholder="Enter Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />

                <label htmlFor="phone">
                  <b>Phone number</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter phone number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />

                <label htmlFor="region">
                  <b>Region</b>
                </label>
                <select
                  name="region"
                  value={formData.region}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled selected>
                    --Select your region--
                  </option>
                  <option value="Ashanti">Ashanti</option>
                  <option value="Brong Ahafo">Brong Ahafo</option>
                  <option value="Central">Central</option>
                  <option value="Eastern">Eastern</option>
                  <option value="Greater Accra">Greater Accra</option>
                  <option value="Northern">Northern</option>
                  <option value="Upper East">Upper East</option>
                  <option value="Upper West">Upper West</option>
                  <option value="Volta">Volta</option>
                  <option value="Western">Western</option>
                  <option value="Savannah">Savannah</option>
                  <option value="Bono East">Bono East</option>
                  <option value="Oti">Oti</option>
                  <option value="Ahafo">Ahafo</option>
                  <option value="Western North">Western North</option>
                  <option value="North East">North East</option>
                </select>

                <label htmlFor="city">
                  <b>City</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter your city"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                />

                <label htmlFor="department">
                  <b>Which Department would like to join?</b>
                </label>
                <select
                  name="department"
                  value={formData.department}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled selected>
                    --Select department--
                  </option>
                  <option value="Communication">Communication</option>
                  <option value="IT">IT</option>
                  <option value="Gender">Gender</option>
                  <option value="Finance">Finance</option>
                  <option value="Secretariat">Secretariat</option>
                  <option value="Operations">Operations</option>
                </select>  

                <label htmlFor="reason">
                  <b>Tell us why you want to join us</b>
                </label>
                <textarea
                  placeholder="Enter why you want to join us"
                  name="reason"
                  value={formData.reason}
                  onChange={handleChange}
                  required
                ></textarea>             

                <div className="clearfix">
                  <button type="submit" className="signupbtn">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Volunteer;

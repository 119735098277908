
import { React, useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import './css/Home.css'


export default function Donate() {
    return (
        <div>
            <Header />

            <header className="site-header section-padding-img site-header-image">
                <div className="container d-block">
                    <div className="row">
                        <div className="col-lg-10 col-12 header-info">
                            <h1>
                                <span className="d-block text-primary">Let's Change the lives of others</span>
                                {/* <span className="d-block text-dark">love to hear you</span> */}
                            </h1>
                        </div>
                    </div>
                </div>
                {/* <img src="images/contact.jpg" className="header-image img-fluid" alt="" /> */}
            </header>


            <section className="contact section-padding">
                <div className="container">
                    <div className="row">

                    <div className="col-lg-6 col-12 mt-5 ms-auto">
                            <div className="row">        
                              
                         
                                <div className="col-md-6 border-top contact-info">
                                    <h6 className="mb-3">MOBILE MONEY</h6>
                                    <ul className="social-icon">
                                        {/* <li><a target="none" href="https://www.facebook.com/molexfoundationafrica" className="social-icon-link bi-facebook"></a></li>
                                        <li><a target="none" href="https://www.linkedin.com/company/molex-foundation-africa/" className="social-icon-link bi-linkedin"></a></li>

                                        <li><a target="none" href="https://www.youtube.com/@molexfoundationafrica4635" className="social-icon-link bi-youtube"></a></li>
                                        <li><a target="none" href="https://www.instagram.com/molex_foundation_21" className="social-icon-link bi-instagram"></a></li>
                                        <li><a target="none" href="https://wa.me/0555089255" className="social-icon-link bi-whatsapp"></a></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-12">
                            <h2 className="mb-4">Let's <span>begin</span></h2>
                            <form className="contact-form me-lg-5 pe-lg-3" role="form">
                                <div className="form-floating">
                                    <input type="text" name="name" id="name" className="form-control" placeholder="Full name" required />
                                    <label htmlFor="name">Full name</label>
                                </div>
                                <div className="form-floating my-4">
                                    <input type="email" name="email" id="email" pattern="[^ @]*@[^ @]*" className="form-control" placeholder="Email address" required />
                                    <label htmlFor="email">Email address</label>
                                </div>
                                <div className="form-floating my-4">
                                    <input type="text" name="subject" id="subject" className="form-control" placeholder="Subject" required />
                                    <label htmlFor="subject">Subject</label>
                                </div>
                                <div className="form-floating mb-4">
                                    <textarea id="message" name="message" className="form-control" placeholder="Leave a comment here" required style={{ height: "160px" }}></textarea>
                                    <label htmlFor="message">Tell us about the project</label>
                                </div>
                                <div className="col-lg-5 col-6">
                                    <button type="submit" className="form-control">Send</button>
                                </div>
                            </form>
                        </div>


                    </div>
                </div>
            </section>

            <header className="site-header container site-header-image">
                <div className="container">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3973.926984403735!2d-1.2930430855529191!3d5.115469839346532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfddfed6fc798569%3A0x7531c2a02fe48636!2sUniversity%20Of%20Cape%20Coast!5e0!3m2!1sen!2sgh!4v1673617004224!5m2!1sen!2sgh"
                        width="100%"
                        height="460"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        className="map"
                    ></iframe>
                </div>
            </header>            
<Footer/>
        </div>
    );
}

import React from "react";
import { useParams } from "react-router-dom"; // Import useParams hook
import Header from "./Header";
import Footer from "./Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./css/Home.css";
import news from "../data/news";
import {Helmet} from "react-helmet";

export default function NewsDetails() {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // Use the useParams hook to get the ID from the URL params
  const { id } = useParams();

  // Find the news item with the desired ID
  const filteredNews = news.filter((item) => item.title === id);

  return (
    <div>
      <Header />

      {/*<section className="contact container-fluid mt-5 section-padding">*/}
        <div className="container mt-4 mt-lg-5 section-padding">
          {filteredNews.map((item, index) => (
            <div key={index} className="row border-bottkom mb-4">
              <Helmet>
              <meta property="og:title" content={item.title} />
              <meta property="og:description" content={item.title} />
              <meta property="og:image" content={item.pictures[0]} />
              <meta property="og:url" content={`https://molexfoundationafrica.org/#/news-detail/${encodeURIComponent(item.title)}`} />
              {/*<meta name="twitter:card" content="summary_large_image" />*/}
            </Helmet>
              <div
                className="col-lg-12 row col-12  ms-auto d-flex p-"
                style={{ justifyContent: "space-between", alignItems: "" }}
              >
                <div className="column col-lg-9 col-12">
                  <div className="col-12 vision-text">
                    <div className="grant-title mt-2 mb-3">{item.title}</div>

                    <div
                      className="text-justify"
                      dangerouslySetInnerHTML={{ __html: item.content }}
                      style={{ textAlign: "justify" }}
                    ></div>
                    <div className="text-danger">{item.date}</div>

                  </div>
                </div>
                <div className="col-lg-2 col-12">
                  {item.pictures.map((pic, idx) => (
                    <div key={idx} className="col-lg-12 col-md-4 col-sm-6 mb-4">
                      <img
                        src={pic}
                        alt={`Picture ${idx}`}
                        style={{ maxWidth: "100%", height: "auto" }} // Adjust the maximum width as needed
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      {/*</section>*/}

      <Footer />
    </div>
  );
}

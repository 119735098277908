import { React, useEffect, useState, useRef } from "react";
// import Header from "./SheroeHeader";
import GuidingHeader from "./GuidingHeader";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./css/Home.css";
import "slick-carousel/slick/slick-theme.css";
import YouTubeEmbed from "./YouTube";
import VideoFrame from "./Video";
import ImageList from "./Gallery";

function NavItem({ id, text, active }) {
  return (
    <li className="nav-item" role="presentation">
      <button
        className={`nav-link${active ? " active" : ""}`}
        id={id}
        data-bs-toggle="pill"
        data-bs-target={`#${id}`}
        type="button"
        role="tab"
        aria-controls={id}
        aria-selected={active}
      >
        {text}
      </button>
    </li>
  );
}

function TabContent({ id, active, children }) {
  return (
    <div
      className={`tab-pane fade${active ? " show active" : ""}`}
      id={id}
      role="tabpanel"
      aria-labelledby={`${id}-tab`}
    >
      {children}
    </div>
  );
}

export default function GuidingStars() {
  const [countdown, setCountdown] = useState(0);
  const joyNewsRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable auto-scrolling
    autoplaySpeed: 4000, // Set auto-scroll speed in milliseconds
  };

  const apiUrl = "https://fresh-link.onrender.com";

  const speakers = [
    {
      id: "patrick-essien",
      img: "2A1A1250.jpg",
      name: "Prof. Nana Ama Browne Klutse",
      role: "Head of Physics Department - University of Ghana, Vice Chair PCC",
      socialLinks: [
        { url: "#", icon: "bi-facebook" },
        { url: "#", icon: "bi-instagram" },
      ],
      bio: `
      `,
    },

    {
      id: "kuta",
      img: "Sarah_DeMartazzi_010924_098.jpg",
      name: "Sarah DeMartazzi",
      role: "Program Manager, Women in STEM - The Henry  Luce Foundation, New York",
      socialLinks: [
        { url: "#", icon: "bi-twitter" },
        { url: "#", icon: "bi-linkedin" },
        { url: "#", icon: "bi-envelope" },
      ],
      bio: ``,
    },

    {
      id: "don",
      img: "2A1A1342.jpg",
      name: "Dr. Regina Esi Turkson",
      role: "Senior Lecturer, Department of Computer Science and Information Technology - University of Cape Coast",
      socialLinks: [
        { url: "#", icon: "bi-youtube" },
        { url: "#", icon: "bi-whatsapp" },
        { url: "#", icon: "bi-instagram" },
      ],
      bio: ``,
    },
    {
      id: "kelly",
      img: "2A1A1270.jpg",
      name: "Adelaide Asantewa Asante",
      role: "Head of West Africa Office for UK Ecology and Hydrology",
      socialLinks: [
        { url: "#", icon: "bi-facebook" },
        { url: "#", icon: "bi-instagram" },
      ],
      bio: ``,
    },
    {
      id: "tsiwah",
      img: "2A1A1512.jpg",
      name: "Dr. Irene Kafui Vorsah Amponsah",
      role: "Lecturer, Department of Statistics - University of Cape Coast",
      socialLinks: [
        { url: "#", icon: "bi-twitter" },
        { url: "#", icon: "bi-linkedin" },
        { url: "#", icon: "bi-envelope" },
      ],
      bio: ``,
    },
  ];

  function fetchData() {
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Data received:", data);
        // Handle the data as needed
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors
      });
  }

  // Call fetchData every 10 seconds
  setInterval(fetchData, 10000);

  useEffect(() => {
    // Function to scroll to a section based on hash
    const scrollToHash = () => {
      const hash = window.location.hash;
      const sectionRef = hash === "#joynews" ? joyNewsRef.current : null;
      if (sectionRef) {
        sectionRef.scrollIntoView({ behavior: "smooth" });
      }
    };

    // Scroll to section if hash exists
    if (window.location.hash) {
      scrollToHash();
    }

    // Set the target date for the countdown (replace 'targetDate' with your desired date)
    const targetDate = new Date("2024-04-26T09:00:00").getTime();

    // Update the countdown every second
    const interval = setInterval(() => {
      // Get the current date and time
      const now = new Date().getTime();

      // Calculate the remaining time in milliseconds
      const distance = targetDate - now;

      // Calculate days, hours, minutes, and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Update the countdown state
      setCountdown(`${days}d\t: \t${hours}h\t: \t${minutes}m\t: \t${seconds}s`);

      // If the countdown is over, clear the interval
      if (distance < 0) {
        clearInterval(interval);
        setCountdown("");
      }
    }, 1000);

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <GuidingHeader />

      <section className="slick-slideshow-sheroes">
        <Slider {...settings} className="">
          <div className="slick-custom pt-5 mt-5">
            <img src="images/molex/3M3A0212.jpg" className="img-fluid" alt="" />

            <div className="slick-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-10">
                    <h2 className="slick-title">Guiding Stars</h2>
                    <h6 className="slick-title mt-lg-3 mb-lg-5">
                    Mentorship and Coaching
                    </h6>
                    <Link to="/contact" className="btn custom-btn">
                      Get in touch
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="slick-custom">
            <img
              src="images/molex/3M3A0129.jpg"
              className="img-fluid"
              alt=""
            />

            <div className="slick-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-10">
                    <h3 className="slick-title">MOLEX Foundation Africa</h3>

                    <p className="lead text-white mt-lg-3 mb-lg-5">
                      We are committed to enriching lives through the provision
                      of life coaching, career guidance, mentoring, and advocacy
                      services.
                    </p>

                    <Link to="/about" className="btn custom-btn">
                      Learn more about us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </Slider>
      </section>

      <section className="about section-paddking mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12 border-colori">
              <div className="tab-content mt-2" id="pills-tabContent">
                <TabContent id="pills-home" active>
                  <div className="row">
                    <div className="col-12">
                      <div className="d- flex-column justify-content-center h-100 ms-lg-4 mt-lg-0 mt-5">
                        <h4 className="mb-3 text-center">
                          <span> Background of Guiding Stars</span>{" "}
                        </h4>
                        <p className="b-text">
                          The Guiding Stars Initiative, launched by the MOLEX
                          Foundation Africa, aims to empower young individuals
                          and students to realize their full potential and
                          become drivers of positive change in their
                          communities. This project focuses on addressing the
                          challenges faced by Junior High Schools (JHS) and
                          Senior High Schools (SHS), especially in rural and
                          underserved areas. By providing mentorship, guidance,
                          and essential life skills, the initiative strives to
                          foster personal development and self-esteem among
                          economically disadvantaged students.
                        </p>
                        <h4 className="mb-3 text-center">
                          <span> Problem Statement</span>{" "}
                        </h4>
                        <p className="b-text">
                          Many children in our society face profound uncertainty
                          about their futures due to poverty and a lack of role
                          models. These children struggle to envision a brighter
                          future and often remain trapped in a cycle of poverty.
                          The absence of mentorship and support systems further
                          stifles their potential. It is crucial to address
                          these challenges and provide the necessary guidance,
                          support, and resources to help these children unlock
                          their potential and break free from poverty.
                        </p>

                        <header
                          className="site-header container-fluid text-center py-4 row section-paddinog-img site-header-image"
                          style={{ backgroundColor: "rgb(10, 10, 10)" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "centeri",
                            }}
                            className="container-fluid column col-12 col-lg-6"
                          >
                            <div className="container-fluid row col-12">
                              <div className="col-12 ">
                                <h2>
                                  <div className="text-light">Purpose</div>
                                  <span className="text-dark theme">
                                    {/*Empowering Girls Through Artificial Intelligence and Robotics*/}
                                  </span>
                                </h2>
                              </div>
                            </div>
                            <div
                              style={{ flexDirection: "column" }}
                              className="container-fluid d-flex justify-content-end  col-12  mt-3"
                            >
                              <p className="b-text text-light col-lg-12">
                                <ul
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    textAlign: "left ",
                                  }}
                                >
                                  <li>
                                    Offer mentorship and coaching to students
                                    lacking positive role models.
                                  </li>
                                  <li>
                                    Foster personal development by equipping
                                    students with essential life skills.
                                  </li>
                                  <li>
                                    Empower students to become catalysts for
                                    positive change in their communities.
                                  </li>
                                  <li>
                                    Break the cycle of poverty through education
                                    and development.
                                  </li>
                                  <li>
                                    Create a lasting impact by providing tools,
                                    resources, and support networks for
                                    students' success beyond school.
                                  </li>
                                </ul>
                              </p>
                            </div>
                          </div>
                          <img
                            src="images/molex/3M3A0215.jpg"
                            className="header-image img-fluid col-12 col-lg-5"
                            alt=""
                          />
                        </header>

                        <h4 className="my-5 text-center">
                          <span> Scope</span>{" "}
                        </h4>
                        <p className="b-text">
                          <h5>The initiative focuses on:</h5>
                          <ul>
                            <li>
                              Vulnerable and economically disadvantaged students
                              in JHS and SHS.
                            </li>
                            <li>
                              Providing mentorship and coaching to build
                              self-esteem and set achievable goals.
                            </li>
                            <li>
                              Equipping students with life skills to overcome
                              obstacles and unlock their potential.
                            </li>
                            <li>
                              Encouraging community engagement and civic
                              responsibility.
                            </li>
                            <li>
                              Ensuring long-term support for sustained growth
                              and development.
                            </li>
                          </ul>
                        </p>

<h5 className="text-center">Pedu M/A Basic School</h5>

                        <header
                          className="site-headerj container-fluid text-center row section-paddinog-img site-header-image"
                          style={{  display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}
                        >
                          
                          <img
                            src="images/molex/3M3A0215.jpg"
                            className="header-image img-fluid col-12 col-lg-6"
                            alt=""
                          />
                          <img
                            src="images/molex/3M3A0215.jpg"
                            className="header-image img-fluid col-12 col-lg-6"
                            alt=""
                          />

                        </header>


                        <h5 className="text-center pt-5"><span>Abrem Essiam Basic School</span></h5>

                        <header
                          className="site-headerj container-fluid text-center row section-paddinog-img site-header-image"
                          style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}
                        >
                          
                          <img
                            src="images/molex/03.jpeg"
                            className="header-image img-fluid col-12 col-lg-6"
                            alt=""
                          />
                          <img
                            src="images/molex/05.jpeg"
                            className="header-image img-fluid col-12 col-lg-6"
                            alt=""
                          />

                        </header>

                        {/* <ImageList/> */}

                        
                        <h4 className="mb-3 text-center">
                          <span> Activities</span>{" "}
                        </h4>
                        <p className="b-text">
                          The Guiding Stars Initiative encompasses several key
                          components:
                          <ul>
                            <li>
                              <strong>Mentorship and Coaching:</strong> Trained
                              mentors and university students visit schools to
                              provide personalized guidance, helping students
                              build self-esteem and set future goals.
                            </li>
                            <li>
                              <strong>Workshops and Seminars:</strong> Organized
                              sessions on personal development, communication,
                              leadership, and problem-solving skills.
                            </li>
                            <li>
                              <strong>Community Engagement:</strong> Encouraging
                              students to participate in volunteerism and civic
                              projects, fostering a sense of responsibility and
                              leadership.
                            </li>
                            <li>
                              <strong>Long-term Support:</strong> Providing
                              ongoing mentorship and resources to ensure
                              sustained growth beyond the project's duration.
                            </li>
                            <li>
                              <strong>Collaborations:</strong> Working with
                              schools, community organizations, and stakeholders
                              to maximize the initiative's impact.
                            </li>
                          </ul>
                        </p>
                   
                        <header
                          className="site-header container-fluid text-center mb-5 py-4 row section-paddinog-img site-header-image"
                          style={{ backgroundColor: "rgb(10, 10, 10)" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "centeri",
                            }}
                            className="container-fluid column col-12 col-lg-6"
                          >
                            <div className="container-fluid row col-12">
                              <div className="col-12 ">
                                <h2>
                                  <div className="text-light">Goals and Objectives</div>
                                  <span className="text-dark theme">
                                    {/*Empowering Girls Through Artificial Intelligence and Robotics*/}
                                  </span>
                                </h2>
                              </div>
                            </div>
                            <div
                              style={{ flexDirection: "column" }}
                              className="container-fluid d-flex justify-content-end  col-12  mt-3"
                            >
                              <p className="b-text text-light col-lg-12">
                                <ul
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    textAlign: "left ",
                                  }}
                                >
                                
                            <li>
                              Empower vulnerable students to realize their
                              potential and become positive change agents.
                            </li>
                            <li>
                              Foster personal development through essential life
                              skills and resilience.
                            </li>
                            <li>
                              Promote community engagement and civic
                              responsibility.
                            </li>
                            <li>
                              Break the cycle of poverty by supporting academic
                              success and economic mobility.
                            </li>
                            <li>
                              Ensure sustainability through support networks and
                              partnerships.
                            </li>
                          </ul>
                              </p>
                            </div>
                          </div>
                          <img
                            src="images/molex/3M3A0139.jpg"
                            className="header-image img-fluid col-12 col-lg-5"
                            alt=""
                          />
                        </header>



                        {/* <h4 className="mb-3 text-center">
                          <span> Methodology</span>{" "}
                        </h4>
                        <p className="b-text">
                          <ul>
                            <li>
                              <strong>Educational Outreach:</strong> Conduct
                              interactive workshops and discussions in rural
                              schools to enlighten students about goal-setting
                              and opportunities beyond their immediate
                              environments.
                            </li>
                            <li>
                              <strong>Community Empowerment:</strong> Provide
                              entrepreneurial and technical training to
                              communities, enhancing skills for sustainable
                              development.
                            </li>
                            <li>
                              <strong>Mentorship and Guidance:</strong>{" "}
                              Establish programs for personalized guidance,
                              career counseling, and leadership development.
                            </li>
                          </ul>
                        </p> */}



                        {/* <h4 className="mb-3 text-center">
                          <span> Needs Assessment</span>{" "}
                        </h4>
                        <p className="b-text">
                          The initiative addresses critical needs such as:
                          <ul>
                            <li>High youth unemployment.</li>
                            <li>
                              Limited access to quality education and training.
                            </li>
                            <li>Lack of mentorship and guidance.</li>
                            <li>Underutilization of youth potential.</li>
                            <li>Desire for positive community impact.</li>
                            <li>
                              Socioeconomic disparities and global challenges.
                            </li>
                            <li>
                              Holistic support for socio-emotional well-being
                              and personal development.
                            </li>
                          </ul>
                        </p> */}
                        {/* <h4 className="mb-3 text-center">
                          <span> Target Audience</span>{" "}
                        </h4>
                        <p className="b-text">
                          The Guiding Stars Initiative targets:
                          <ul>
                            <li>
                              Young individuals (aged 15-30), including students
                              and youth seeking personal and professional
                              development.
                            </li>
                            <li>
                              Parents and guardians supporting their children's
                              education.
                            </li>
                            <li>Educators and school administrators.</li>
                            <li>
                              Community leaders and local government officials.
                            </li>
                            <li>Employers and business leaders.</li>
                            <li>Nonprofit organizations and NGOs.</li>
                            <li>
                              Government agencies and civil society
                              organizations.
                            </li>
                          </ul>
                        </p> */}
                        {/* <h4 className="mb-3 text-center">
                          <span> Coordinators</span>{" "}
                        </h4>
                        <p className="b-text">
                          <ul>
                            <li>
                              <strong>Dr. Patrick Essien:</strong> CEO of MOLEX
                              Foundation Africa, providing visionary leadership
                              and strategic planning.
                            </li>
                            <li>
                              <strong>Mr. Nathaniel Bimpong:</strong> Board
                              Member, offering expertise in business management
                              and corporate governance.
                            </li>
                            <li>
                              <strong>Miss Charlotte Owusu Ansah:</strong>{" "}
                              Gender Coordinator, promoting gender equality and
                              inclusion.
                            </li>
                          </ul>
                        </p>
                        <h4 className="mb-3 text-center">
                          <span> Expected Results</span>{" "}
                        </h4>
                        <p className="b-text">
                          The initiative aims to achieve:
                          <ul>
                            <li>Increased youth empowerment and confidence.</li>
                            <li>Improved readiness for the workforce.</li>
                            <li>
                              Positive community changes driven by empowered
                              youth.
                            </li>
                            <li>Reduced youth unemployment rates.</li>
                            <li>
                              Improved academic performance and motivation.
                            </li>
                            <li>
                              Emergence of youth leaders equipped for community
                              roles.
                            </li>
                            <li>Greater social cohesion and inclusivity.</li>
                            <li>
                              Long-term social, economic, and environmental
                              sustainability.
                            </li>
                            <li>
                              Recognition and support for scalability and
                              expansion.
                            </li>
                            <li>
                              Inspiration for future generations to become
                              agents of change.
                            </li>
                          </ul>
                        </p> */}
                        <div className="mt-0 mt-lg-auto"></div>
                      </div>
                    </div>
                  </div>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

// Mission.jsx
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import SideNav from "./SideNav";


const longText = `In the year 2021, a group of young scholars assembled in the
tranquil village of Nyanfeku Ekroful, nestled within the AAK district. Bound by
a shared vision and propelled by an unwavering determination to give back to the
society that had nurtured them, these remarkable individuals embarked on a trans
formative journey. Their formative years, spent navigating the challenges of impoverished
circumstances, had not dampened their spirits; instead, they had harnessed these trials as
catalysts for change. This pivotal juncture marked the genesis of the MOLEX Foundation
Africa – an embodiment of their collective resolve to amplify the human experience
through an intricate tapestry of life coaching, career guidance, mentoring, and advocacy
services. Their resounding mission was to spark the flame of greatness within individuals,
empowering them to transcend their circumstances and accomplish extraordinary feats.  At the heart of their mission, the MOLEX Foundation Africa champions a tapestry of transformative
initiatives. Central to their endeavors is the laudable "Girls-in-STEM" program, an illuminating beacon for
young women in remote rural areas, both in junior and senior high schools. Rooted in the firm belief that
gender should never be a barrier to brilliance, this initiative is a manifestation of the foundation's
commitment to igniting passion and breaking stereotypes. By nurturing a profound interest in Science,
Technology, Engineering, and Mathematics (STEM), particularly among girls, the program not only paves the
way for career aspirations but also dismantles the traditional underrepresentation of women in these fields.
Through empowerment, education, and hands-on engagement, it serves as a conduit for young women to become
architects of their own futures. <p>
    MOLEX Foundation Africa's luminous mission extends far beyond classroom walls. As fervent advocates for
    change, the foundation steadfastly confronts and combats the scourge of Sexual and Gender-Based Violence
    (SGBV). With unyielding resolve, they stand as a collective voice against the injustices that plague
    society, nurturing a culture where safety, respect, and equality thrive. </p>
<p>
    In a world grappling with the profound challenges of climate change, the MOLEX Foundation Africa emerges
    as
    a harbinger of resilience and education. Through extensive outreach and enlightening initiatives, they
    empower the public to develop the resilience necessary to confront the catastrophic implications of
    climate
    change. With a call to action that reverberates across communities, the foundation actively engages
    individuals in vital mitigation and adaptation measures.</p>
<p>
    Guided by an unshakeable commitment to nurturing transformative leaders, the MOLEX Foundation Africa
    shapes
    a future where leadership is defined by values and potential. Their endeavors strive to curtail the
    transformation of leadership culture, sculpting a landscape where integrity, empathy, and visionary
    thinking
    flourish. </p>
The story of MOLEX Foundation Africa resonates as a symphony of determination, compassion, and unwavering
dedication. Rooted in personal experiences, fueled by a fervent desire to uplift others, and propelled by a
shared vision of greatness, this foundation becomes a beacon of hope. With every initiative, every
mentorship, and every stand against injustice, they carve a path toward a more inclusive, empowered, and
promising tomorrow.`;


function Mission() {
  const missionRef = useRef(null);
  const visionRef = useRef(null);
  const coreValuesRef = useRef(null);
  const historyRef = useRef(null);
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <Header />
      <section className="testimonial section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto text-center-no">
              <div className="slick-testimonial" ref={missionRef}>
                <div className="slick-testimonialp-caption">
                  <p className="mission-text mb-5 pt-5 pt-lg-0">
                    <h5 className="text-center-no mt-5">
                      <span className="mt-5">Our Mission</span>
                    </h5>
                    MOLEX Found Africa is committed to enhancing quality of life
                    by offering life coaching, career guidance, mentoring, and
                    advocacy services that inspire individuals to pursue
                    greatness.
                  </p>
                </div>
              </div>

              <div className="slick-testimonial" ref={visionRef}>
                <div className="slick-testimonialp-caption">
                  <p className="mission-text mb-5">
                    <h5 className="text-center-no mt-5">
                      <span>Our</span> Vision
                    </h5>
                    <div className="vision-text">
                    <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Spike students' interest in Science Technology
                        Engineering and Mathematics (STEM) especially the
                        girl-child.
                      </p>
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Create equal opportunities in our society for all to
                        pursue their dreams.
                      </p>
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Coach girls and women to live beyond the cultural and
                        gender limitations.
                      </p>
                      
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Combat against Sexual and Gender Based Violence.
                      </p>
                      
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Raise leaders with great potential and ensure values
                        that will curb the required transformation of leadership
                        culture in the society.
                      </p>
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Educate the public to develop resilience for Climate
                        Change catastrophe and participate in the mitigation and
                        adaptation measures.
                      </p>
                      {/* Add other vision points */}
                    </div>
                  </p>
                </div>
              </div>

              <div className="slick-testimonial" ref={coreValuesRef}>
                <div className="slick-testimonialp-caption">
                  <p className="mission-text mb-5">
                    <h5 className="text-center-no mt-5">
                      <span>Our</span> Core values
                    </h5>
                    <div className="vision-text">
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Excellent Service
                      </p>
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Teamwork
                      </p>
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Volunteerism
                      </p>
                      {/* <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Combat against Sexual and Gender Based Violence.
                      </p> */}
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Person Focused
                      </p>
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Respect
                      </p>

                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Servant Leadership
                      </p>
                      {/* Add other core values */}
                    </div>
                  </p>
                </div>
              </div>
              <div className="slick-testimonial" ref={historyRef}>
      <div className="slick-testimonialp-caption">
        <p className="mission-text mb-5">
          <h5 className="text-center-no mt-5">
            <span>History</span>
          </h5>
          <div className="vision-text">
            {showMore ? (
              <>
<p style={{ textAlign: "justify"}} dangerouslySetInnerHTML={{ __html: longText }}></p>                <br/><br/>
                <span onClick={toggleShowMore} className="hist-more mt-5">
                  Read less
                </span>
              </>
            ) : (
              <>
                In the year 2021, a group of young scholars assembled in the tranquil village of Nyanfeku Ekroful, nestled within the AAK district. Bound by a shared vision and propelled by an unwavering determination to give back to the society that had nurtured them, these remarkable individuals embarked on a transformative journey. Their formative years, spent navigating the challenges of impoverished circumstances, had not dampened their spirits; instead, they had harnessed these trials as catalysts for change. This pivotal juncture marked the genesis of the MOLEX Foundation Africa – an embodiment of their collective resolve to amplify the human experience through an intricate tapestry of life coaching, career guidance, mentoring, and advocacy services.{' '}
                <br/><br/>
                <span onClick={toggleShowMore} className="hist-more mt-5">
                  Read more
                </span>
              </>
              
            )}
          </div>
         
        </p>
      </div>
    </div>
            </div>

            <SideNav
              scrollToMission={() => scrollToSection(missionRef)}
              scrollToVision={() => scrollToSection(visionRef)}
              scrollToCoreValues={() => scrollToSection(coreValuesRef)}
              scrollToHistory={() => scrollToSection(historyRef)}
            />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Mission;
